import React, {FC} from 'react';
import {Content, ContentWrapper, Wrapper} from './styled'
import Navigation from "../Navigation/Navigation";
import {Spinner} from "../index";

type Props = {
    title: string;
    isLoading?: boolean;
    breadcrumbs: Array<{
        link: string,
        name: string
    }>;
    children: React.ReactNode;
    aside?: React.ReactNode;
};

const PageContentContainer: FC<Props> = ({title, isLoading = false, breadcrumbs, children, aside}) => {
    return (
        <Wrapper>
            <Navigation title={title} items={breadcrumbs}/>
            <Spinner isLoading={isLoading}/>
            <ContentWrapper>
                {aside && aside}
                <Content $isAside={!!aside}>
                    {children}
                </Content>
            </ContentWrapper>
        </Wrapper>
    );
};

export default PageContentContainer;
