export const emailPattern = {
    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Invalid Email Address"
};

export const zipPattern = {
    value: /^[0-9\b]+$/,
    message: "Invalid Zip Code"
};

export const passwordPattern = {
    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+\-={}[\]\\|;:"'<>,.?/]{8,}$/,
    message: 'Minimum 8 characters, at least one uppercase and lowercase letter and one number',
}

export const phonePattern = {
    value: /^\+?[1-9]\d{1,14}$/,
    message: "Invalid Phone Number"
};
