import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';
import {IGlossaryField} from "../../types";

export const fetchGlossary = createAsyncThunk('glossary/list', async (params: any) => {
    const response = await api.get('glossary/list', { params });
    return response.data;
});

interface GlossaryState {
    items: Array<IGlossaryField>;
    isLoading: boolean;
}

const initialState: GlossaryState = {
    items: [],
    isLoading: false,
};

export const glossarySlice = createSlice({
    name: 'glossary',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchGlossary.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchGlossary.fulfilled, (state: any, action: any) => {
                state.items = action.payload.data.resource;
                state.isLoading = false;
            })
            .addCase(fetchGlossary.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(state=> {
                state.isLoading = false;
            });
    },
});

export default glossarySlice.reducer;
