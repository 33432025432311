import React from 'react';
import {ReactComponent as LegendSurvey} from './../../../../../../assets/images/icons/legend-survey.svg';
import {ReactComponent as LegendUnep} from './../../../../../../assets/images/icons/legend-unep.svg';
import {ReactComponent as LegendBoth} from './../../../../../../assets/images/icons/legend-both.svg';
import {Text, Wrapper} from "./styled";

const OptionsLegend = () => {
    return (
        <Wrapper>
            <LegendUnep/>
            <Text>UNEP responses only</Text>
            <LegendSurvey/>
            <Text>Survey responses only</Text>
            <LegendBoth/>
            <Text>UNEP and Survey/match responses</Text>
        </Wrapper>
    )
};

export default OptionsLegend;
