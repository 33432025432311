import styled from "styled-components";
import {Link} from "react-router-dom";

export const SignInForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 30px;
`;

export const ImgStyled = styled.img`
    margin-top: 28px;
    margin-bottom: 40px;
`;

export const FormGroup = styled.div`
  margin-bottom: 16px;
  width: 90%;
  max-width: 340px;
  min-width: 200px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 35px;
  margin-bottom: 10px;
`;

export const A = styled(Link)`
  margin-top: 10px;
  color: ${(props) => props.theme.terracottaColor};
`;

export const TwoFA = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const H3 = styled.h3`
  margin: 0 0 20px;
  font-size: 16px;
`;

export const H4 = styled.h4`
  margin: 0 0 8px 0;
  font-size: 12px;
  font-weight: 400;
`;

export const Resend = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  color: #989898;
  margin-top: -8px;
  ${A} {
    margin: 0 0 -20px 0;
    font-size: 12px;
  }
`;