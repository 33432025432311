import React, {FC} from 'react';
import {BreadcrumbItem, Breadcrumbs, StyledLink, Title, Wrapper} from "./styled";

type Props = {
    title: string;
    items: Array<{
        link: string,
        name: string
    }>;
};

const Navigation: FC<Props> = ({title = '', items = []}) => {

    const breadcrumbs = [{link: '/', name: 'Search'}, ...items];

    return (
        <Wrapper>
            {!!title.length && (
                <Title>{title}</Title>
            )}
            {!!breadcrumbs.length && (
                <Breadcrumbs>
                    {breadcrumbs.map((item, key)=>(
                        <BreadcrumbItem key={key}><StyledLink to={item.link}>{item.name}</StyledLink></BreadcrumbItem>
                    ))}
                </Breadcrumbs>
            )}
        </Wrapper>
    );
};

export default Navigation;
