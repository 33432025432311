import React, {FC} from 'react';
import {A, Copyright, Img, Wrapper} from "./styled";
import logo from "../../../assets/images/logo-footer.png";
import moment from "moment/moment";
import {APP_URI} from "../../../constants/api";

const Footer: FC = () => {
    return (
        <Wrapper>
            <Img src={logo}/>
            <A to={`${APP_URI}/privacy-policy.pdf`} target="_blank">Privacy Policy</A>
            <Copyright>© {moment().format('YYYY')} ICCA. All rights reserved.</Copyright>
        </Wrapper>
    );
};

export default Footer;
