export const defaultTheme = {
    whiteColor: "#F7F8F9",
    terracottaColor: "#DC661E",
    yellowColor: "#e8b909",
    brownColor: "#b97f6c",
    greyColor: "#7F7F7F",
    greenColor: "#008D58",
    blueColor: "#0099ca",
    blue2Color: "#004994",
    darkBlueColor: "#00244e",
    ultravioletColor: "#8F4180",
    redColor: "#BA0646",
    lightGreyColor: "#E4E4E4",
    darkGreyColor: "#666",
    backgroundColor: "#F7F8F9",
    blackTextColor: "#232424",
    greyTextColor: "#7F7F7F",
    inputColor: "#D0E0E5",
    fontFamily: '"Mulish", sans-serif',
    fontFamilySecondary: '"Mulish", serif',
    errorColor: "#f30000",
    disabledBackgroundColor: "#faf8f8",
    disabledTextColor: "#c0c0c0",
};
