import React, {ChangeEvent, FC, InputHTMLAttributes, useEffect, useRef, useState} from 'react';
import {ControllerRenderProps} from "react-hook-form/dist/types/controller";
import {DeleteBtn, Image, ImageWrapper, Wrapper} from "./styled";
import {Button} from "../index";

const Uploader: FC<Omit<ControllerRenderProps, 'ref'>> = ({onChange, name, value}) => {
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState('');

     useEffect(() => {
        if (setFile) setFile(value);
     }, [value]);

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        const fileReader = new FileReader();
        if (file) {
            fileReader.readAsDataURL(file);
            fileReader.onload = (event) => {
                if (event?.target?.result) {
                    setFile(event?.target?.result as string);
                    onChange(event?.target?.result);
                }
            }
        }
    }

    const handleUploadClick = () => {
        hiddenFileInput.current?.click()
    }

    const handleDelete = () => {
        setFile('');
    }

    return (
        <>
            {file ? (
                <Wrapper>
                    <ImageWrapper>
                        <DeleteBtn onClick={handleDelete}>Delete</DeleteBtn>
                        <Image src={file} alt=''/>
                    </ImageWrapper>
                </Wrapper>
            ) : (
                <>
                    <Button
                        title='Browse & Upload'
                        color={'terracotta'}
                        size='MD'
                        icon='upload'
                        onClick={handleUploadClick}
                    />
                    <input
                        ref={hiddenFileInput}
                        name={name}
                        onChange={handleOnChange}
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                    />
                </>
            )}
        </>
    );
};

export default Uploader;
