import React, {FC} from 'react';
import {CheckboxStyled} from './styled';
import {CheckboxProps} from "@mui/material/Checkbox/Checkbox";
import {FormControlLabel} from "@mui/material";

interface Props extends CheckboxProps {
    name: string;
    label?: string | React.ReactNode;
}

const Checkbox: FC<Props> = ({label = '', ...props}) => {
    return (
        <FormControlLabel
            className={props.className}
            control={
                <CheckboxStyled
                    {...props}
                />
                }
            label={label}
        />
    );
};

export default Checkbox;
