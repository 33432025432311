import styled from "styled-components";
import {Button, Toggle} from "../../../../controls";

export const StyledAside = styled.aside`
    width: 260px;
    background-color: ${(props) => props.theme.blackTextColor};
    padding-top: 36px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
`;

export const StyledButton = styled(Button)`
  font-size: 16px;
  height: 57px;
    width: 100%;
  text-transform: uppercase;
  border-radius: 0;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 8px 0 0;
`;

export const Rollover = styled.div`
  display: none;
  position: absolute;
  top: calc(100% + 12px);
  left: 5px;
  padding: 5px;
  max-width: 200px;
  background-color: #EBF0F5;
  border-radius: 4px;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 14px;
  z-index: 2;

  &:before {
    background-color: #EBF0F5;
    content: ' ';
    position: absolute;
    width: 12px;
    height: 12px;
    rotate: 45deg;
    top: -6px;
    left: calc(50% - 6px);
  }
`;

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
  position: relative;
  cursor: pointer;
  &:hover {
    ${Rollover} {
      display: block;
    }
  }
`;

export const StyledToggle = styled(Toggle)`
    border: '1px solid red';
`;
