import styled from "styled-components";

export const Title = styled.div`
  margin-bottom: 27px;
  border-bottom: 1px solid #E4E4E4;
`;

export const H2Styled = styled.h2`
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  font-family: ${(props) => props.theme.fontFamilySecondary};
`;

export const Content = styled.div`
`;

export const Table = styled.div`
    display: grid;
    grid-template-columns: 20% 20% 60%;
`;

export const Row = styled.div<{$odd?: boolean}>`
  display: contents;
  
  & > div {
    background-color: ${(props) => props?.$odd ? props.theme.backgroundColor : '#FFF'};
  }

  &:hover > div {
    background-color: #EBF0F5;
    font-weight: 700;
  }
`;

export const CellHeader = styled.div`
    display: flex;
    align-items: center;
    min-height: 48px;
    padding: 12px 12px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    color: ${(props) => props.theme.greyColor};
    border-bottom: 1px solid #E4E4E4;
`;

export const Cell = styled(CellHeader)`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.blackTextColor};
`;
