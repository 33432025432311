import styled from "styled-components";
import {InputProps} from "./Input";

export const Wrapper = styled.div`
    display: block;
`;

export const Container = styled.div<{ $inputSize: InputProps['inputSize'] }>`
  display: flex;
  position: relative;

  ${({$inputSize}) => $inputSize && (
    $inputSize === 'XL' ? {height: 52} :
    $inputSize === 'MD' ? {height: 44} :
    $inputSize === 'SM' ? {height: 40} : {}
  )}
`;

export const StyledLabel = styled.label`
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: ${(props) => props.theme.greyColor};
`;

export const StyledInput = styled.input<InputProps>`
  margin: 0;
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 14px;
  border: 1px solid #D0E0E5;
  border-radius: 2px;
  font-weight: 400;
  font-size: 16px;
  color: ${(props) => props.theme.blackTextColor};

  ${({theme, error}) => !!error && ({
    borderColor: theme.errorColor,
  })}
  
  &:focus {
    outline: none;
  }

  &::placeholder {
    font-weight: 400;
    color: #a4b1b4;
  }

  &:disabled {
    background-color: ${(props) => props.theme.disabledBackgroundColor};
    color: ${(props) => props.theme.disabledTextColor};
  }

  ${({icon}) => !!icon && (
      'padding-left: 40px;'
  )}
`;

export const IconImg = styled.img`
  position: absolute;
  left: 10px;
  top: 12px;
  z-index: 2;
`;

export const StyledPasswordInput = styled(StyledInput)`
  padding-right: 45px;
`;

export const EyeImg = styled.img`
  position: absolute;
  top: 12px;
  right: 16px;
  cursor: pointer;
`;

export const Error = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: ${(props) => props.theme.errorColor};
`;
