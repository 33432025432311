import styled from "styled-components";

export const StyledLabel = styled.label`
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: ${(props) => props.theme.greyColor};
`;
