import React, {FC} from 'react';
import {StyledInput, StyledLabel, StyledToggleLabel, ToggleWrapper} from "./styled";

type Props = {
    label?: string;
    variant?: 'default' | 'dark';
    onChange?: React.ChangeEventHandler<HTMLInputElement>
}

const Toggle: FC<Props> = ({label = '', onChange, variant = 'default'}) => {
    return (
        <ToggleWrapper>
            <StyledInput variant={variant} type="checkbox" id="toggle" onChange={onChange}/>
            <StyledLabel variant={variant} htmlFor="toggle">Toggle</StyledLabel>
            <StyledToggleLabel variant={variant}>{label}</StyledToggleLabel>
        </ToggleWrapper>
    );
};

export default Toggle;
