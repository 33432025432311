import React, {FC, ForwardedRef, forwardRef, useEffect, useImperativeHandle, useMemo} from 'react';
import {useForm} from "react-hook-form";
import {IChemical, IField, IFieldCategory, IResult} from "../../../../../types";
import {fields} from "../../../../../constants/FormFields";
import {Table} from "../";

type Props = {
    isDescShown: boolean,
    fieldsList: Array<IFieldCategory>,
    chemical: IChemical | null,
    handleFormSubmit: (data: any) => void,
    setIsFormUpdated: React.Dispatch<React.SetStateAction<boolean>>,
    ref: ForwardedRef<{handleSave: ()=>void}>
}

const Form: FC<Props> = forwardRef(({isDescShown, fieldsList, chemical, handleFormSubmit, setIsFormUpdated}: Props, ref: ForwardedRef<{handleSave: () => void}>) => {

    const defaultValues = useMemo(() => {
        const items: IResult = {};
        fields.forEach((item: IField) => {
            items[item.name] = item.defaultValue;
        })
        return items;
    }, [fields]);

    const values = useMemo(() => {
        const items: IResult = {};
        if (chemical) {
            fields.forEach((item: IField) => {
                items[item.name] = chemical[item.name] as any ?? null;
            })
        }
        return items;
    }, [chemical]);

    const {control, handleSubmit, reset, formState: {errors, isDirty, dirtyFields }} = useForm({
        mode: 'onBlur',
        defaultValues: defaultValues
    });

    useEffect(() => {
        if (values) {
            reset(values);
        }
    }, [values]);

    useImperativeHandle(ref, () => ({
        handleSave() {
            handleSubmit((data) => handleFormSubmit(data))();
        },
    }));

    useEffect(() => {
        setIsFormUpdated(isDirty);
    }, [isDirty]);

    return (
        <Table fieldsList={fieldsList} isDescShown={isDescShown} form={{control, errors}}/>
    )
});

Form.displayName = 'Form';

export default Form;
