import styled from "styled-components";
import {Radio} from "../../../../../../controls";
import {RadioGroup} from "@mui/material";

export const Radios = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const StyledRadio = styled(Radio)`
`;
