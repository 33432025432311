import React, {FC, useMemo} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks/global';
import PageContentContainer from "../../common/PageContentContainer/PageContentContainer";
import {DataTable} from "../../common";
import {setFilterParams} from "../../../store/chemical/chemicalSlice";
import {defaultTheme} from "../../../themes/main";
import SearchPanel from "./parts/SearchPanel/SearchPanel";
import {Content, H2Styled, Title, StyledLink} from "./styled";
import {capitalizeFirstLetter} from "../../../utils";

const Chemicals: FC = () => {
    const breadcrumbs = [
        {
            link: '/chemicals',
            name: 'Search & List View',
        }
    ]
    const columns = [
        {
            name: 'CASRN',
            alias: 'casrn',
            selector: (row: any) => row.casrn,
            sortable: true,
            width: '140px',
            cell: (row: any) =><div>
                <StyledLink to={`/chemical/${row.id}`}>
                    <span>{row.casrn}</span>
                </StyledLink>
            </div>,
        },
        {
            name: 'Name',
            alias: 'name',
            selector: (row: any) => row.name,
            sortable: true,
            width: '400px',
        },
        {
            name: 'InChIKey',
            alias: 'inchi_key',
            selector: (row: any) => row.inchi_key,
            sortable: true,
            width: '450px',
        },
        {
            name: 'Confidence of Use in Plastic',
            alias: 'confidence_plastic_commerce',
            selector: (row: any) => capitalizeFirstLetter(row.confidence_plastic_commerce),
            sortable: true,
            width: '250px',
            style: {
                color: defaultTheme.ultravioletColor
            }
        },
    ];
    const dispatch = useAppDispatch();
    const {chemicals, isLoading, listFilters, pagination} = useAppSelector(state => state.chemical);
    const {user, role} = useAppSelector(state => state.auth);

    const isViewOnly = useMemo(()=>{
        return !(role === 'admin' || role === 'admin');
    }, [role])

    const handleChangePage = (page: number) => {
        dispatch(setFilterParams({...listFilters, page}));
    };

    const handleSort = (order_by: string, order_dir: string) => {
        dispatch(setFilterParams({...listFilters, order_by, order_dir, page: 1}));
    };

    return (
        <PageContentContainer title={'Search & List View'} isLoading={isLoading} breadcrumbs={breadcrumbs}>
            <Title>
                <H2Styled>ICCA Chemical Search</H2Styled>
            </Title>
            <Content>
                <SearchPanel isViewOnly={isViewOnly}/>
                <DataTable
                    data={chemicals}
                    columns={columns}
                    currentPage={pagination.current_page}
                    paginationTotalRows={pagination.total}
                    paginationPerPage={pagination.per_page}
                    lastPage={pagination.last_page}
                    from={pagination.from}
                    to={pagination.to}
                    onChangePage={handleChangePage}
                    onSort={handleSort}
                    pagination={true}
                />
            </Content>
        </PageContentContainer>
    );
};

export default Chemicals;
