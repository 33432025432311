import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 250px 250px;
  column-gap: 20px;
`;

export const Value = styled.div`
  padding: 4px;
  font-weight: 500;
`;
