import React, {FC} from 'react';
import {Controller, FieldError} from "react-hook-form";
import {Control} from "react-hook-form/dist/types";
import {IField} from "../../../../../../../types";
import {StyledSelect} from "./styled";

type Props = {
    item: IField,
    control: Control<any>
    error: FieldError;
}

const FormSelect: FC<Props> = ({item, control, error}) => {
    const {name, label, rules, options} = item;
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { ref, ...field } }) => (
                <StyledSelect
                    {...field}
                    placeholder={label}
                    options={options}
                    error={error}
                    style={{
                        height: '40px',
                        fontSize: '14px',
                    }}
                />
            )}
        />
    );
};

export default FormSelect;
