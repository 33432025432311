import React, {FC, useEffect} from 'react';
import 'react-phone-input-2/lib/style.css'
import {Error, StyledLabel, StyledPhoneInput, Wrapper} from "./styled";
import {FieldError} from "react-hook-form";

interface Props {
    className?: string;
    country?: string,
    label?: string,
    value: any,
    onChange: any;
    disabled?: boolean;
    error?: FieldError;
}


const PhoneInput: FC<Props> = ({className, country = 'us' , value, onChange, label = '', disabled = false, error, ...props}) => {
   return (
       <Wrapper className={className}>
           {!!label.length && (
               <StyledLabel>{label}</StyledLabel>
           )}
           <StyledPhoneInput
               containerClass={className}
               country={country ?? 'us'}
                value={value}
               onChange={onChange}
               {...props}
           />
           {!!error && error.message && (
               <Error className={'errorMsg'}>
                   {`${error.message}`}
               </Error>
           )}
       </Wrapper>
   )
};

export default PhoneInput;
