import React, {FC, useMemo} from 'react';
import {IFieldCategory} from "../../../../../types";
import {Table} from "../";

type Props = {
    isDescShown: boolean,
    fieldsList: Array<IFieldCategory>
}

const List: FC<Props> = ({isDescShown, fieldsList}) => {

    const fieldsListFiltered = useMemo<Array<IFieldCategory>>(() => {
        return fieldsList.reduce((acc: Array<IFieldCategory>, field: IFieldCategory) => {
            const availableFields = field.fields.filter(item => !item.show_if_true || (item.show_if_true && item.value));
            return (availableFields.length === 0) ? acc : [...acc, {...field, fields: availableFields}];
        }, []);

    }, [fieldsList]);

    return (
        <Table fieldsList={fieldsListFiltered} isDescShown={isDescShown}/>
    );
};

export default List;
