import React, {Fragment} from 'react';
import {IField, IFieldCategory} from "../../../../../types";
import {Control} from "react-hook-form/dist/types";
import {capitalizeFirstLetter} from "../../../../../utils";
import {Evidence} from "../";
import FormControl from "../Form/FormControl";
import TwoColumnsValue from "../TwoColumnsValue/TwoColumnsValue";
import {
    ComingSoon,
    Desc,
    GroupHeader,
    Label,
    SubstanceImage,
    SubstanceTypeImage,
    TitleCell,
    Value,
    ValueCell,
    WideTableHeader,
    DefaultTable,
    WideTable,
    NoData,
    TableHeaderGroup
} from "./styled";
import TwoColumnsSurveyUnep from "../TwoColumnsSurveyUnep/TwoColumnsSurveyUnep";

type TableProps = {
    fieldsList: Array<IFieldCategory>,
    isDescShown: boolean,
    form?: {
        control: Control<any>,
        errors: any
    }
}

type TableComponentProps = {
    items: Array<IField>,
    isDescShown: boolean,
    form?: {
        control: Control<any>,
        errors: any
    }
}

type ListValueComponentProps = {
    item: IField,
}

type FormValueComponentProps = {
    item: IField,
    form: {
        control: Control<any>,
        errors: any
    }
}

const WideTableComponent = (({items, isDescShown, form}: TableComponentProps) => {
    return (
        <WideTable>
            <div/>
            <WideTableHeader>UNEP Designation</WideTableHeader>
            <WideTableHeader>EU CLP<ComingSoon>* Coming Soon</ComingSoon></WideTableHeader>
            <WideTableHeader>Japan<ComingSoon>* Coming Soon</ComingSoon></WideTableHeader>
            <WideTableHeader>U.S. OSHA<ComingSoon>* Coming Soon</ComingSoon></WideTableHeader>
            <WideTableHeader>More...<ComingSoon>* Coming Soon</ComingSoon></WideTableHeader>
            {items.map((item) => (
                <Fragment key={item.name}>
                    <TitleCell isForm={!!form}>
                        <Label>{item.label}</Label>
                        {isDescShown && <Desc>{item.desc}</Desc>}
                    </TitleCell>
                    <ValueCell>
                        {form ? (
                            <FormValueComponent item={item} form={form}/>
                        ) : (
                            <ListValueComponent item={item}/>
                        )}
                    </ValueCell>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </Fragment>
            ))}
        </WideTable>
    );
});

const UseTableComponent = (({items, isDescShown, form}: TableComponentProps) => {
    return (
        <DefaultTable>
            <div/>
            <TableHeaderGroup>
                <WideTableHeader>UNEP Designation</WideTableHeader>
                <WideTableHeader>ICCA Survey</WideTableHeader>
            </TableHeaderGroup>
            {items.map((item) => (
                <Fragment key={item.name}>
                    <TitleCell isForm={!!form}>
                        <Label>{item.label}</Label>
                        {isDescShown && <Desc>{item.desc}</Desc>}
                    </TitleCell>
                    <ValueCell>
                        {form ? (
                            <FormValueComponent item={item} form={form}/>
                        ) : (
                            <ListValueComponent item={item}/>
                        )}
                    </ValueCell>
                </Fragment>
            ))}
        </DefaultTable>
    );
});

const DefaultTableComponent = (({items, isDescShown, form}: TableComponentProps) => {
    return (
        <DefaultTable>
            {items.map((item) => (
                <Fragment key={item.name}>
                    <TitleCell isForm={!!form}>
                        <Label>{item.label}</Label>
                        {isDescShown && <Desc>{item.desc}</Desc>}
                    </TitleCell>
                    <ValueCell>
                        {form ? (
                            <FormValueComponent item={item} form={form}/>
                        ) : (
                            <ListValueComponent item={item}/>
                        )}
                    </ValueCell>
                </Fragment>
            ))}
        </DefaultTable>
    );
});

const ListValueComponent = (({item}: ListValueComponentProps) => {
    const {value, type, is_evidence_translate, is_multilines, is_uppercase} = item;
    const showBoolean = (value: string | number | boolean | null | undefined) => {
        return (value === 1) ? 'Yes' : (value === 0) ? 'No' : '';
    }

    if (is_evidence_translate) {
        return (
            <Evidence item={item}/>
        );
    } else if (type === 'image' && value) {
        return (
            <SubstanceTypeImage>
                <SubstanceImage src={value as string} alt={''}/>
            </SubstanceTypeImage>
        );
    } else if (type === 'boolean') {
        return (
            <Value>{showBoolean(value)}</Value>
        );
    } else if (is_multilines) {
        return ((item.name === 'function' || item.name === 'industrial_sector' || item.name === 'polymer')
                ? <TwoColumnsSurveyUnep value={value as string} name={item.name}/>
                : <TwoColumnsValue value={value as string}/>
        );
    } else if (is_uppercase) {
        return (
            <Value>{capitalizeFirstLetter(value as string)}</Value>
        );
    } else {
        return (
            <Value>{value}</Value>
        );
    }
});

const FormValueComponent = (({item, form}: FormValueComponentProps) => {
    return (
        <FormControl
            item={item}
            control={form.control}
            errors={form.errors}
        />
    );
});

export const Table = (({fieldsList, isDescShown, form}: TableProps) => {
    return (
        <>
            {fieldsList.length ? fieldsList.map(({category, label, fields}) => !!fields.length && (
                <Fragment key={label}>
                    <GroupHeader>{label}</GroupHeader>
                    {(category === 'Confidence in Hazard Classification') ? (
                        <WideTableComponent items={fields} isDescShown={isDescShown} form={form}/>
                    ) : (category === 'Use') ? (
                            <UseTableComponent items={fields} isDescShown={isDescShown} form={form}/>
                        ) : (
                            <DefaultTableComponent items={fields} isDescShown={isDescShown} form={form}/>
                        )
                    }
                </Fragment>
            )) : (
                <NoData>No data available for this section</NoData>
            )}
        </>
    );
});

export default Table;
