import React, {FC} from 'react';
import {Control} from "react-hook-form/dist/types";
import {IField} from "../../../../../types";
import {FormInput, FormSelect, FormRadio} from "./controls";
import FormUploader from "./controls/FormUploader/FormUploader";

type Props = {
    item: IField,
    control: Control<any>,
    errors: any
}

const FormControl: FC<Props> = ({item, control, errors}) => {
    const {name, type} = item;

    if (type === 'input') {
        return <FormInput item={item} control={control} error={errors[name]} />
    } else if (type === 'select') {
        return <FormSelect item={item} control={control} error={errors[name]} />
    } else if (type === 'boolean') {
        return <FormRadio item={item} control={control} error={errors[name]} />
    } else if (type === 'image') {
        return <FormUploader item={item} control={control}/>
    } return null;
};

export default FormControl;
