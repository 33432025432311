import styled from "styled-components";

export const Rollover = styled.div`
  display: none;
  position: absolute;
  top: calc(100% + 12px);
  left: 0;
  padding: 5px;
  max-width: 150px;
  background-color: #EBF0F5;
  border-radius: 4px;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 14px;
  z-index: 2;

  &:before {
    background-color: #EBF0F5;
    content: ' ';
    position: absolute;
    width: 12px;
    height: 12px;
    rotate: 45deg;
    top: -6px;
    left: 15px;
  }
`;

export const EvidenceWrapper = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  &:hover {
    ${Rollover} {
      display: block;
    }
  }
`;

export const EvidenceText = styled.span`
  font-size: 14px;
`;
