import {createSlice} from '@reduxjs/toolkit';

interface GlobalState {
    isOpened: boolean;
    type: string;
    message: string;
    errors: Array<any>;
    cb: any;
}

const initialState: GlobalState = {
    isOpened: false,
    type: 'success',
    message: '',
    errors: [],
    cb: ()=>{},
};

export const alertSlice = createSlice({
    name: 'alert',
    initialState,

    reducers: {
        showSuccessAlert: (state, action) => {
            state.isOpened = true;
            state.type = 'success';
            state.message = action.payload.message || '';
            state.cb = action.payload.cb || null;
        },
        showErrorAlert: (state, action) => {
            state.isOpened = true;
            state.type = 'error';
            state.message = action.payload?.message || '';
            state.errors = handleErrors(action.payload?.errors || []);
        },
        hideAlert: (state) => {
            state.isOpened = false;
            state.type = 'success';
            state.message = '';
            state.errors = [];
            state.cb = ()=>{};
        },
        setAlert: (state, action) => {
            state.isOpened = action.payload?.isOpened || false;
            state.type = action.payload?.type || '';
            state.message = action.payload?.message || '';
            state.errors = handleErrors(action.payload?.errors || []);
            if (action.payload?.isOpened === false) {
                state.type = 'success';
                state.message = '';
                state.errors = [];
            }
        },
    },
});

const handleErrors = (errors: Array<any>) => {
    const ret: Array<string> = [];
    if (errors) {
        Object.keys(errors).forEach((key: any) => {
            if (key && errors[key]) {
                errors[key].forEach((item: any) => {
                    ret.push(item);
                })
            }
        });
    }
    return ret;
}

export const {showSuccessAlert, showErrorAlert, hideAlert, setAlert} = alertSlice.actions;
export default alertSlice.reducer;
