import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {fetchGlossary} from "../../../store/glossary/glossarySlice";
import {Cell, CellHeader, Content, H2Styled, Row, Table, Title} from "./styled";
import {IGlossaryField} from "../../../types";
import {PageContentContainer} from "../../common";

const Glossary: FC = () => {

    const breadcrumbs = [
        {
            link: '/glossary',
            name: 'Glossary of Terms',
        }
    ]

    const ipp = 20;
    const dispatch = useAppDispatch();
    const {items, isLoading} = useAppSelector(state => state.glossary);
    const [isScrollLoading, setIsScrollLoading] = useState(false);
    const [shownItems, setShownItems] = useState<Array<IGlossaryField>>([]);

    useEffect(() => {
        dispatch(fetchGlossary({}));
    }, []);

    useEffect(() => {
        if (items && items.length) {
            setShownItems(items.slice(0, ipp));
        }
    }, [items]);

    const handleScroll = () => {
        if (Math.round(window.innerHeight + document.documentElement.scrollTop) !== document.documentElement.offsetHeight) {
            return;
        }

        setIsScrollLoading(true)

        setTimeout(() => {
            setShownItems([...items.slice(0, (shownItems.length + ipp))]);
            setIsScrollLoading(false);
        }, 500);
    };

    useEffect(() => {
        if (shownItems.length < items.length) {
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, [shownItems]);

    return (
        <PageContentContainer title={'Glossary of Terms'} isLoading={isLoading || isScrollLoading} breadcrumbs={breadcrumbs}>
            <Title>
                <H2Styled>Glossary of Terms</H2Styled>
            </Title>
            <Content>
                <Table>
                    <Row>
                        <CellHeader>Field Name</CellHeader>
                        <CellHeader>Values</CellHeader>
                        <CellHeader>Description</CellHeader>
                    </Row>
                    {!!shownItems.length && shownItems.map((item: IGlossaryField, key: number) => (
                        <Row key={item.alias} $odd={!(key % 2)}>
                            <Cell>{item.label}</Cell>
                            <Cell>{item.example}</Cell>
                            <Cell>{item.desc}</Cell>
                        </Row>
                    ))}
                </Table>
            </Content>
        </PageContentContainer>
    );
};

export default Glossary;
