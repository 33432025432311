import styled from "styled-components";

export const RegionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-top: 40px;
`;
export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const CountriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  width: 70%;
`;

export const Countries = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
  gap: 16px;
  margin-top: 10px;
`;

export const Country = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const CountryTitle = styled.div`
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const NoTitle = styled.span`
  width: 100%;
  display: flex;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
`;
