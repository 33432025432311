import React, {FC} from 'react';
import BaseModal from "./BaseModal";
import { ReactComponent as WarningIcon } from '../../../assets/images/icons/icon-warning-yellow.svg';
import {Button} from "../../controls";
import {
    AlertHeader,
    Warning,
    ConfirmationAlertFooter,
} from "./styled";

interface Props {
    isOpen: boolean,
    text?: string,
    onClose: () => void,
    onConfirm: () => void,
}

const ConfirmationModal: FC<Props> = ({isOpen = false, text= 'Confirmation', onClose, onConfirm, ...props}) => {
    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            maxWidth={400}
            {...props}
        >
            <AlertHeader>
                <WarningIcon/>
                <Warning>{text}</Warning>
            </AlertHeader>
            <ConfirmationAlertFooter>
                <Button
                    onClick={onConfirm}
                    title='CONFIRM'
                    color={'green'}
                    size='LG'
                />
                <Button
                    onClick={onClose}
                    title={'CANCEL'}
                    color={'green-transparent'}
                    size='LG'
                />
            </ConfirmationAlertFooter>
        </BaseModal>
    );
};

export default ConfirmationModal;
