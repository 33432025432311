import styled from "styled-components";
import {RadioGroup} from "@mui/material";
import {Input, PhoneInput, SingleSelect} from "../../controls";

export const Title = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 27px;
  padding-bottom: 16px;
  border-bottom: 1px solid #E4E4E4;
`;

export const Content = styled.form`
  display: block;
`;

export const HeaderWrapper = styled.div`
  display: flex;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 40px;
`;

export const H2Styled = styled.h2`
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  font-family: ${(props) => props.theme.fontFamilySecondary};
  max-width: 850px;;
  word-break: break-all;
`;

export const RoleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

export const Radios = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 5px;
  @media screen and (max-width: 880px){
    flex-direction: column !important;
    gap: 0;
  }
`;

export const FormInput = styled(Input)`
  width: 100%;
  max-width: 277px;
  min-width: 150px;
`;

export const FormSingleSelect = styled(SingleSelect)`
  width: 100%;
  max-width: 277px;
`;

export const FormPhoneInput = styled(PhoneInput)`
  width: 100%;
  max-width: 277px;
  min-width: 150px;
  
  & input {
    width: 100%;
    max-width: 277px;
    min-width: 150px;
  }
  
`;