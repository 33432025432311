import React, {FC, useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../hooks/global';
import {Spinner} from "../../common";
import Logo from './../../../assets/images/logo.svg'
import {A, ButtonWrapper, FormGroup, H3, ImgStyled, Form} from "./styled";
import {Controller, useForm} from "react-hook-form";
import {Button, Input} from "../../controls";
import {fetchSetPassword} from "../../../store/auth/authSlice";
import {ROUTES} from "../../../router/routes";
import {passwordPattern} from "../../../helpers/validationRules";

type FormProps = {
    password: string,
    password_confirm: string
}

const SetPassword: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get('token');
    const {isLoading} = useAppSelector(state => state.auth);
    const {control, handleSubmit, getValues, reset, formState: {errors, touchedFields, isValid}} = useForm({
        mode: 'onBlur',
        defaultValues: {
            password: '',
            password_confirm: '',
        }
    });

    useEffect(() => {
        if (!token) navigate('/sign-in');
    }, [token]);

    const submitButtonStatus = () => {
        return !(!Object.keys(errors).length || isValid) || isLoading;
    };

    const handleOnSubmit = (data: FormProps) => {
        dispatch(fetchSetPassword({password: data.password, token: token as string}))
            .then(resp => {
                if (resp?.type === 'auth/set-password/fulfilled') {
                    reset();
                    navigate('/sign-in');
                }
        });
    }

    const handleFormSubmit = () => {
        handleSubmit((data) => handleOnSubmit(data))();
    }

    return (
        <Form>
            <ImgStyled src={Logo}/>
            <Spinner isLoading={isLoading}/>

            <H3>Please establish an account password</H3>

            <FormGroup>
                <Controller
                    name={"password"}
                    control={control}
                    rules={{
                        required: "This field is required",
                        pattern: passwordPattern
                    }}
                    render={({ field: { ref, ...field } }) => (
                        <Input
                            {...field}
                            label={'Password'}
                            type={'password'}
                            placeholder={'Enter Password'}
                            inputSize='XL'
                            error={errors.password}
                        />
                    )}
                />
            </FormGroup>
            <FormGroup>
                <Controller
                    name={"password_confirm"}
                    control={control}
                    rules={{
                        required: "This field is required",
                        validate: (value) => {
                            return value === getValues('password') ? true : 'Passwords should be the same';
                        }
                    }}
                    render={({ field: { ref, ...field } }) => (
                        <Input
                            {...field}
                            label={'Confirm Password'}
                            type={'password'}
                            placeholder={'Confirm Password'}
                            inputSize='XL'
                            error={errors.password_confirm}
                        />
                    )}
                />
            </FormGroup>
            <ButtonWrapper>
                <Button
                    title='SET PASSWORD'
                    onClick={handleFormSubmit}
                    size='XXL'
                    color='green'
                    variant='secondary'
                    disabled={submitButtonStatus()}
                />
            </ButtonWrapper>
            <A to={ROUTES.SIGN_IN}>Back to Sign In</A>
        </Form>
    );
};

export default SetPassword;
