import React, {FC, KeyboardEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../hooks/global';
import {Spinner} from "../../common";
import Logo from './../../../assets/images/logo.svg'
import {A, ButtonWrapper, FormGroup, H3, H4, ImgStyled, Resend, SignInForm, TwoFA} from "./styled";
import {Controller, useForm} from "react-hook-form";
import {Button, Input} from "../../controls";
import {fetchSignIn} from "../../../store/auth/authSlice";
import {ROUTES} from "../../../router/routes";
import {emailPattern} from "../../../helpers/validationRules";

const SignIn: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [is2fa, setIs2fa] = useState(false);
    const [phone, setPhone] = useState('');
    const {isLoading} = useAppSelector(state => state.auth);
    const [seconds, setSeconds] = useState(60);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const {control, handleSubmit, getValues, setValue, trigger, reset , formState: {errors, touchedFields, isValid}} = useForm({
        mode: 'onBlur',
        defaultValues: {
            username: '',
            password: '',
            otp: '',
        }
    });

    const submitButtonStatus = () => {
        return !(!Object.keys(touchedFields).length || isValid) || isLoading;
    };

    const handleOnSubmit = (data: any) => {
        dispatch(fetchSignIn(data)).then((resp: any) => {
            if (resp?.type === 'auth/sign-in/fulfilled') {
                if (resp.payload.code === 200) {
                    reset();
                    navigate('/chemicals');
                } else {
                    setIs2fa(true);
                    setPhone(hidePhoneDigits(resp.payload.data.resource.phone ?? ''));
                }
            }
            setIsTimerRunning(true);
            setSeconds(60);
        });
    }

    const handleFormSubmit = () => {
        handleSubmit((data) => handleOnSubmit(data))();
    }

    const handleResend = () => {
        setValue('otp', '');
        handleOnSubmit(getValues());
    }

    const handleBack = () => {
        setIs2fa(false);
        setIsTimerRunning(false);
        setValue('otp', '');
        trigger();
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleFormSubmit();
        }
    }

    function hidePhoneDigits(phoneNumber: string) {
        return phoneNumber.replace(/(\+\d{4})\d+(\d{2})$/, (match, p1, p2) => {
            return `${p1}${'*'.repeat(match.length - p1.length - p2.length)}${p2}`;
        });
    }

    useEffect(() => {
        let intervalId: any;

        if (isTimerRunning && seconds > 0) {
            intervalId = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);
        } else {
            setIsTimerRunning(false);
        }

        return () => clearInterval(intervalId);
    }, [isTimerRunning, seconds]);

    return (
        <SignInForm onKeyDown={handleKeyDown} tabIndex={1}>
            <ImgStyled src={Logo}/>
            <Spinner isLoading={isLoading}/>
            {is2fa ? (
                <TwoFA>
                    <H3>2 Factor Authentication</H3>
                    <H4>We sent a code to your phone {phone}.</H4>
                    <H4>The code will expire in 10 minutes.</H4>
                    <H4> </H4>
                    <FormGroup>
                        <Controller
                            name={"otp"}
                            control={control}
                            rules={{
                                required: "This field is required",
                            }}
                            render={({ field: { ref, ...field } }) => (
                                <Input
                                    {...field}
                                    label={'Code'}
                                    placeholder={'Enter your 2FA code'}
                                    inputSize='XL'
                                    maxLength={4}
                                    error={errors.otp}
                                />
                            )}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Resend>
                            {isTimerRunning
                                ? `Resend in  ${seconds} seconds`
                                : <A to={''} onClick={handleResend}>Resend code</A>
                            }
                        </Resend>
                    </FormGroup>
                </TwoFA>
            ) : (
                <>
                    <FormGroup>
                        <Controller
                            name={"username"}
                            control={control}
                            rules={{
                                required: "This field is required",
                                pattern: emailPattern
                            }}
                            render={({ field: { ref, ...field } }) => (
                                <Input
                                    {...field}
                                    label={'Email'}
                                    placeholder={'Enter Email'}
                                    inputSize='XL'
                                    width={320}
                                    error={errors.username}
                                />
                            )}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Controller
                            name={"password"}
                            control={control}
                            rules={{
                                required: "This field is required",
                            }}
                            render={({ field: { ref, ...field } }) => (
                                <Input
                                    {...field}
                                    label={'Password'}
                                    type={'password'}
                                    placeholder={'Enter Password'}
                                    inputSize='XL'
                                    error={errors.password}
                                />
                            )}
                        />
                    </FormGroup>
                </>
            )}
            <ButtonWrapper>
                <Button
                    title='LOGIN'
                    onClick={handleFormSubmit}
                    size='XXL'
                    color='green'
                    variant='secondary'
                    disabled={submitButtonStatus()}
                />
            </ButtonWrapper>
            {is2fa ? (
                <A to={''} onClick={handleBack}>Back</A>
            ) : (
                <>
                    <A to={ROUTES.SIGN_UP}>Register for an account today!</A>
                    <A to={ROUTES.FORGOT_PASSWORD}>Forgot Password?</A>
                </>
            )}
        </SignInForm>
    );
};

export default SignIn;
