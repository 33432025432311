import React, {FC} from 'react';
import {Wrapper, Value, CellWrapper} from "./styled";
import {IChemicalOption} from "../../../../../types";

type Props = {
    value: any
    name: string
}

const TwoColumnsSurveyUnep: FC<Props> = ({value, name}) => {
    const unepItems = value.filter((item: IChemicalOption) => item.type === 'unep' || item.type === 'both');
    const unepMiddleIndex = Math.ceil(unepItems.length / 2);
    const unepFirstHalf = unepItems.slice(0, unepMiddleIndex);
    const unepSecondHalf = unepItems.slice(unepMiddleIndex);
    const surveyItems = value.filter((item: IChemicalOption) => item.type === 'survey' || item.type === 'both');
    const surveyMiddleIndex = Math.ceil(surveyItems.length / 2);
    const surveyFirstHalf = surveyItems.slice(0, surveyMiddleIndex);
    const surveySecondHalf = surveyItems.slice(surveyMiddleIndex);

    return(
        <Wrapper>
                <CellWrapper>
                    <div>
                        {!!unepFirstHalf && unepFirstHalf.map((item: any) => (
                            <Value
                                key={`${item.id}_${item.title}`}
                                isBold={item.type === 'both'}
                            >
                                {item.title}
                            </Value>
                        ))}
                    </div>
                    <div>
                        {!!unepSecondHalf && unepSecondHalf.map((item: any) => (
                            <Value
                                key={`${item.id}_${item.title}`}
                                isBold={item.type === 'both'}
                            >
                                {item.title}
                            </Value>
                        ))}
                    </div>
                </CellWrapper>
            <CellWrapper>
                <div>
                    {!!surveyFirstHalf && surveyFirstHalf.map((item: any) => (
                        <Value
                            key={`${item.id}_${item.title}`}
                            isBold={item.type === 'both'}
                        >
                            {item.title}
                        </Value>
                    ))}
                </div>
                <div>
                    {!!surveySecondHalf && surveySecondHalf.map((item: any) => (
                        <Value
                            key={`${item.id}_${item.title}`}
                            isBold={item.type === 'both'}
                        >
                            {item.title}
                        </Value>
                    ))}
                </div>

            </CellWrapper>
        </Wrapper>
    );
};

export default TwoColumnsSurveyUnep;
