import React, {FC, InputHTMLAttributes, useState} from 'react';
import {FieldError} from "react-hook-form";
import eyeOpen from '../../../assets/images/icons/eye-open.svg'
import eyeOff from '../../../assets/images/icons/eye-off.svg'
import {
    Container,
    Error,
    EyeImg,
    IconImg,
    StyledInput,
    StyledLabel,
    StyledPasswordInput,
    Wrapper
} from './styled';

export interface InputProps extends InputHTMLAttributes<HTMLElement> {
    className?: string;
    label?: string;
    type?: string;
    inputSize?: 'XL'|'MD'|'SM';
    error?: FieldError;
    icon?: string;
}

const Input: FC<InputProps> = ({className, type = 'text', label= '', icon, inputSize = 'MD', error, ...props}) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <Wrapper className={className}>
            {!!label.length && (
                <StyledLabel>{label}</StyledLabel>
            )}
            <Container $inputSize={inputSize}>
                {!!icon && (
                    <IconImg src={icon} width={20} height={20} alt=''/>
                )}
                {(type==='text') && (
                    <StyledInput
                        icon={icon}
                        type={type}
                        error={error}
                        {...props}
                    />
                )}
                {(type==='password') && (
                    <>
                        <StyledPasswordInput
                            type={showPassword ? 'text' : 'password'}
                            error={error}
                            {...props}
                        />
                        <EyeImg src={showPassword ? eyeOff : eyeOpen} onClick={()=>setShowPassword(!showPassword)}/>
                    </>
                )}
            </Container>
            {!!error && error.message && (
                <Error className={'errorMsg'}>
                    {`${error.message}`}
                </Error>
            )}
        </Wrapper>
    );
};
export default Input;
