import {ISelectOption} from "../types";

export const booleanOptions: Array<ISelectOption> = [
    {value: '', label: 'Select One'},
    {value: 1, label: 'Yes'},
    {value: 0, label: 'No'},
];

export const confidenceOptions: Array<ISelectOption> = [
    {value: '', label: 'Select One'},
    {value: 'low', label: 'Low'},
    {value: 'medium', label: 'Medium'},
    {value: 'high', label: 'High'},
];

export const evidenceOptions: Array<ISelectOption> = [
    {value: '', label: 'Select One'},
    {value: 0, label: '0.0'},
    {value: 0.5, label: '0.5'},
    {value: 1, label: '1.0'},
];

export const numberOptions: Array<ISelectOption> = [
    {value: '', label: 'Select One'},
    {value: 1, label: '1'},
    {value: 2, label: '2'},
    {value: 3, label: '3'},
    {value: 4, label: '4'},
    {value: 5, label: '5'},
    {value: 6, label: '6'},
    {value: 7, label: '7'},
    {value: 8, label: '8'},
    {value: 9, label: '9'},
    {value: 10, label: '10'},
    {value: 11, label: '11'},
    {value: 12, label: '12'},
    {value: 13, label: '13'},
    {value: 14, label: '14'},
    {value: 15, label: '15'},
    {value: 16, label: '16'},
    {value: 17, label: '17'},
    {value: 18, label: '18'},
];

export const tonnageOptions: Array<ISelectOption> = [
    {value: '', label: 'Select One'},
    {value: -1, label: '-1'},
    {value: 0, label: '0'},
    {value: 1, label: '1'},
    {value: 10, label: '10'},
    {value: 100, label: '100'},
    {value: 1000, label: '1,000'},
    {value: 2000, label: '2,000'},
    {value: 10000, label: '10,000'},
    {value: 100000, label: '100,000'},
    {value: 1000000, label: '1,000,000'},
    {value: 10000000, label: '10,000,000'},
    {value: 100000000, label: '100,000,000'},
    {value: 1000000000, label: '1,000,000,000'},
];
