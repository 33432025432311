import styled from "styled-components";
import {defaultTheme} from "../../../themes/main";

export const customStyles = {
    table: {
        style: {
            fontFamily: defaultTheme.fontFamily,
        },
    },
    headRow: {
        style: {
            minHeight: '48px',
            fontSize: '14px',
            fontWeight: 700,
            color: defaultTheme.greyColor,
        },
    },
    headCells: {
        style: {
            paddingLeft: '12px',
            paddingRight: '12px',
        },
    },
    cells: {
        style: {
            padding: '12px',
        },
    },
    rows: {
        style: {
            fontSize: '14px',
            fontWeight: 400,
            minHeight: '48px',
            borderBottom: '1px solid #E4E4E4 !important',
            '&:hover div': {
                backgroundColor: '#EBF0F5',
                fontWeight: 700
            },
        },
    },
};

export const customTheme = {
    text: {
        primary: defaultTheme.blackTextColor,
    },
    striped: {
        default: defaultTheme.backgroundColor,
        text: defaultTheme.blackTextColor,
    },
};

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 36px;

  @media screen and (max-width: 570px){
    flex-direction: column-reverse;
  }
`;

export const Counter = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => props.theme.darkGreyColor};
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding-bottom: 15px;
  height: 32px;

    & a:first-child {
        margin-right: 10px;
    }

    & a:last-child {
        margin-left: 10px;
    }
`;

export const StyledButton = styled.button<any>`
  border: 1px solid;
  border-color: ${(props) => props.theme.blueColor};
  margin-left: 4px;
  box-sizing: border-box;
  color: ${(props) => props.theme.blackTextColor};
  background-color: white;
  border-radius: 2px;
  font-weight: 600;
  font-size: 14px;
  width: 80px;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.blueColor};
    color: white;
  }
  
  &:disabled {
    background-color: #f3f3f3;
    color: #cbcbcb;
    border: none;
  }
  
  ${(props) => props.$active && {
    backgroundColor: props.theme.blueColor,
    color: 'white', 
    border: 'none',
  }}
`;

export const StyledButtonLink = styled.a<{$isDisabled: boolean}>`
    color: ${(props) => props.theme.blueColor};
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
    
    &:hover,
    &:active {
        transform: scale(1.05);
    }

    ${({theme, $isDisabled}) => $isDisabled && ({
        color: '#cbcbcb'
    })}
}
`;

export const SmallButton = styled(StyledButton)`
  height: 40px;
  width: 40px;

  & svg {
    position: relative;
    top: 1px;
  }

  &:hover svg {
    stroke: #FFF;
    & path {
      stroke: #FFF;
    }
  }

  &:disabled svg {
    stroke: #a4b1b4;
    & path {
      stroke: #a4b1b4;
    }
  }
`;

export const Arrow = styled.img`
  top: 1px;
  position: relative;
`;
