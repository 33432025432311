import React, {useEffect, useMemo, useState} from 'react';
import {Controller, useForm, useWatch} from "react-hook-form";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import BeforeUnloadComponent from 'react-beforeunload-component';
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {fetchUserById, fetchUserCreate, fetchUserUpdate, resetUserDetails} from "../../../store/user/userSlice";
import {ICountry, ISelectOption, IState, IUserFormData} from "../../../types";
import {ConfirmationModal, PageContentContainer} from "../../common";
import {Buttons} from "./parts";
import {ROUTES} from "../../../router/routes";
import {Label, Radio} from "../../controls";
import {fetchCountries, fetchStates} from "../../../store/general/generalSlice";
import {emailPattern} from "../../../helpers/validationRules";
import { isValidPhoneNumber } from 'libphonenumber-js';
import {
    Content,
    FieldsWrapper,
    H2Styled,
    Radios,
    RoleWrapper,
    Title,
    HeaderWrapper,
    FormInput,
    FormSingleSelect, FormPhoneInput
} from "./styled";
import {Backdrop} from "@mui/material";

const breadcrumbs = [
    {
        link: '/users',
        name: 'User Management Search & List View'
    },
    {
        link: '',
        name: 'Used Details'
    }
]

const User = () => {
    const {id} = useParams();
    const {pathname} = useLocation()
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {user, isLoading} = useAppSelector(state => state.user);
    const {countries, states} = useAppSelector(state => state.general);
    const [isCancelModalShown, setIsCancelModalShown] = useState(false);
    const {user:  authUser} = useAppSelector(state => state.auth);
    const isCurrentUser = user?.id === authUser?.id;

    const {control, handleSubmit, reset, setValue, getValues, formState: {errors, isDirty, touchedFields, isValid}} = useForm({
        mode: 'onChange',
        defaultValues: {
            email: '',
            phone: '',
            first_name: '',
            last_name: '',
            role: 'admin',
            password: '',
            country_id: 1,
            state_id: 0,
            province: '',
            city: '',
            organization: '',
            function: '',
        }
    });

    useEffect(() => {
        dispatch(fetchCountries());
        dispatch(fetchStates());
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(fetchUserById(id)).then(resp => {
                if (resp.type === 'users/get/rejected') {
                    navigate(ROUTES.USERS, {state: {keepFilters: true}});
                }
            });
        }
        return () => {
            dispatch(resetUserDetails());
        };
    }, [id]);

    useEffect(() => {
        if (user) {
            reset({
                email: user.email,
                phone: user.address.phone ? user.address.phone.replace('+', '') : '',
                first_name: user.profile.first_name,
                last_name: user.profile.last_name,
                role: user.role.alias,
                country_id: user.address.country_id,
                state_id: user.address.state_id ?? 0,
                province: user.address.province ?? '',
                city: user.address.city ?? '',
                organization: user.profile.organization ?? '',
                function: user.profile.function ?? '',
            });
        }
    }, [user]);

    const countryId = useWatch({
        control,
        name: 'country_id',
    });

    useEffect(() => {
        if (countryId && isDirty) {
            setValue('state_id', 0);
            setValue('province', '');
        }
    }, [countryId]);

    const countriesOptions = useMemo(() => {
        return countries ? countries.map((item: ICountry): ISelectOption => ({label: item.title, value: item.id})) : []
    }, [countries]);

    const statesOptions = useMemo(() => {
        const items = states
            ? states.filter((item) => item.country_id === countryId).map((item: IState): ISelectOption => ({label: item.title, value: item.id}))
            : []
        return [{label: 'Select One', value: 0}, ...items];
    }, [states, countryId]);

    const handleFormSubmit = (data: IUserFormData) => {
        if (isNewUser) {
            dispatch(fetchUserCreate({data})).then(resp => {
                if (resp?.type === 'users/create/fulfilled') {
                    navigate(`/user/${resp.payload.data.resource.id}`, { replace: true });
                }
            });
        } else if (id) {
            dispatch(fetchUserUpdate({id, data}));
        }
    }

    const isNewUser = useMemo(() => {
        return (pathname === '/user/new');
    }, [pathname]);

    const handleArchive = (active: boolean) => {
        dispatch(fetchUserUpdate({id, data: {is_active: active}}));
    }

    const handleSave = () => {
        handleSubmit((data) => handleFormSubmit(data))();
    };

    const handleBack = () => {
        if (isDirty) {
            setIsCancelModalShown(true);
        } else {
            navigate(ROUTES.USERS, { state: { keepFilters: true } });
        }
    };

    const handleModalComponentHandler = ({handleModalLeave, handleModalCancel}: any) => {
        return isDirty ? (
            <ConfirmationModal
                isOpen={true}
                onClose={handleModalCancel}
                onConfirm={handleModalLeave}
                text={'Your form changes are unsaved. If you exit now, your edits will be lost. Confirm the exit?'}
            />
        ) : handleModalLeave();
    }

    const handleCancelModalShownCancel = () => {
        setIsCancelModalShown(false);
    }

    const handleCancelModalShownConfirm = () => {
        setIsCancelModalShown(false);
        navigate(ROUTES.USERS, { state: { keepFilters: true } });
    }

    return (
        <PageContentContainer title={isNewUser ? 'Create New User' : 'User Details'} isLoading={isLoading} breadcrumbs={breadcrumbs}>
            <Title>
                <HeaderWrapper>
                    <H2Styled>{isNewUser ? 'Create New User' : `User Details: ${user?.profile?.first_name}`}</H2Styled>
                </HeaderWrapper>
                <Buttons
                    isActive={!!user && !!user.is_active}
                    isNewUser={isNewUser}
                    isCurrentUser={isCurrentUser}
                    handleSave={handleSave}
                    handleBack={handleBack}
                    handleArchive={handleArchive}
                />
            </Title>
            <BeforeUnloadComponent
                blockRoute={true}
                ignoreBeforeunloadDocument={!isDirty}
                ignoreChildrenLinks={true}
                modalComponentHandler={handleModalComponentHandler}
            >
                <Content>
                    <RoleWrapper>
                        <Label>User Role</Label>
                        <Controller
                            name='role'
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <Radios
                                    row={true}
                                    {...field}
                                >
                                    <Radio
                                        label='Super Administrator'
                                        value='admin'
                                    />
                                    <Radio
                                        label='Basic Administrator'
                                        value='staff'
                                    />
                                    <Radio
                                        label='NGO Researcher'
                                        value='user'
                                    />
                                    <Radio
                                        label='Individual Researcher'
                                        value='user-individual'
                                    />
                                </Radios>
                            )}
                        />
                    </RoleWrapper>
                    <FieldsWrapper>
                        <Controller
                            name='first_name'
                            control={control}
                            rules={{required: 'This field is required'}}
                            render={({field: {ref, ...field}}) => (
                                <FormInput
                                    {...field}
                                    label='First Name*'
                                    placeholder='First Name'
                                    error={errors.first_name}
                                />
                            )}
                        />
                        <Controller
                            name='last_name'
                            control={control}
                            rules={{required: 'This field is required'}}
                            render={({field: {ref, ...field}}) => (
                                <FormInput
                                    {...field}
                                    label='Last Name*'
                                    placeholder='Last Name'
                                    error={errors.last_name}
                                />
                            )}
                        />
                        <Controller
                            name='email'
                            control={control}
                            rules={{
                                required: "This field is required",
                                pattern: emailPattern,
                            }}
                            render={({field: {ref, ...field}}) => (
                                <FormInput
                                    {...field}
                                    label='Email Address*'
                                    placeholder='Email Address'
                                    error={errors.email}
                                />
                            )}
                        />
                        <Controller
                            name='phone'
                            control={control}
                            rules={{
                                required: "This field is required",
                                validate: (value: string) => isValidPhoneNumber(`+${value}`) || 'Phone number is invalid'
                            }}
                            render={({field: {ref, ...field}}) => (
                                <FormPhoneInput
                                    {...field}
                                    label='Mobile Phone #*'
                                    error={errors.phone}
                                />
                            )}
                        />
                        <Controller
                            name='password'
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <FormInput
                                    {...field}
                                    type='password'
                                    label='Password'
                                    placeholder='Password'
                                    error={errors.password}
                                />
                            )}
                        />
                    </FieldsWrapper>
                    <FieldsWrapper>
                        <Controller
                            name='country_id'
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <FormSingleSelect
                                    {...field}
                                    label='Country'
                                    options={countriesOptions}
                                    error={errors.country_id}
                                />
                            )}
                        />
                        {(countryId === 1 || countryId === 2) && (
                            <Controller
                                name='state_id'
                                control={control}
                                render={({field: {ref, ...field}}) => (
                                    <FormSingleSelect
                                        {...field}
                                        label='State/Province'
                                        options={statesOptions}
                                        error={errors.state_id}
                                    />
                                )}
                            />
                        )}
                        {(countryId !== 1 && countryId !== 2) && (
                            <Controller
                                name='province'
                                control={control}
                                defaultValue={''}
                                render={({field: {ref, ...field}}) => (
                                    <FormInput
                                        {...field}
                                        label='State/Province'
                                        placeholder='State/Province'
                                        error={errors.province}
                                    />
                                )}
                            />
                        )}
                        <Controller
                            name='city'
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <FormInput
                                    {...field}
                                    label='City'
                                    placeholder='City'
                                    error={errors.city}
                                />
                            )}
                        />
                    </FieldsWrapper>
                    <FieldsWrapper>
                        <Controller
                            name='organization'
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <FormInput
                                    {...field}
                                    label='Organization'
                                    placeholder='Organization'
                                    error={errors.organization}
                                />
                            )}
                        />
                        {/*<Controller*/}
                        {/*    name='function'*/}
                        {/*    control={control}*/}
                        {/*    render={({field: {ref, ...field}}) => (*/}
                        {/*        <FormInput*/}
                        {/*            {...field}*/}
                        {/*            label='Role/Function'*/}
                        {/*            placeholder='Role/Function'*/}
                        {/*            error={errors.function}*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/*/>*/}
                    </FieldsWrapper>
                </Content>
            </BeforeUnloadComponent>
            <ConfirmationModal
                isOpen={isCancelModalShown}
                onClose={handleCancelModalShownCancel}
                onConfirm={handleCancelModalShownConfirm}
                text={'Your form changes are unsaved. If you exit now, your edits will be lost. Confirm the exit?'}
            />
        </PageContentContainer>
    );
};

export default User;
