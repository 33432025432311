import React, {FC, useMemo} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks/global';
import PageContentContainer from "../../common/PageContentContainer/PageContentContainer";
import {DataTable} from "../../common";
import {defaultTheme} from "../../../themes/main";
import SearchPanel from "./parts/SearchPanel/SearchPanel";
import {Content, H2Styled, Title, StyledLink} from "./styled";
import {setFilterParams} from "../../../store/user/userSlice";
import {IUsersListItem} from "../../../types";

const breadcrumbs = [
    {
        link: '/users',
        name: 'User Management Search & List View',
    }
]

const Users: FC = () => {

    const columns = [
        {
            name: 'First Name',
            alias: 'first_name',
            selector: (row: IUsersListItem) => row.first_name,
            sortable: true,
            cell: (row: any) =><div>
                <StyledLink to={`/user/${row.id}`}>
                    <span>{row.first_name}</span>
                </StyledLink>
            </div>,
        },
        {
            name: 'Last Name',
            alias: 'last_name',
            selector: (row: IUsersListItem) => row.last_name,
            sortable: true,
        },
        {
            name: 'Email Address',
            alias: 'email',
            selector: (row: IUsersListItem) => row.email,
            sortable: true,
        },
        {
            name: 'User Role',
            alias: 'role',
            selector: (row: IUsersListItem) => row.role,
            sortable: true,
        },
        {
            name: 'Status',
            alias: 'is_active',
            selector: (row: IUsersListItem) => row.is_active ? 'Active' : 'Archived',
            sortable: true,
            style: {
                color: defaultTheme.ultravioletColor
            }
        },
    ];

    const dispatch = useAppDispatch();
    const {users, isLoading, listFilters, pagination} = useAppSelector(state => state.user);
    const {user, role} = useAppSelector(state => state.auth);

    const isViewOnly = useMemo(() => {
        return !(user && (role === 'admin' || role === 'staff'));
    }, [user])

    const handleChangePage = (page: number) => {
        dispatch(setFilterParams({...listFilters, page}));
    };

    const handleSort = (order_by: string, order_dir: string) => {
        dispatch(setFilterParams({...listFilters, order_by, order_dir, page: 1}));
    };

    return (
        <PageContentContainer title={'User Management Search & List View'} isLoading={isLoading} breadcrumbs={breadcrumbs}>
            <Title>
                <H2Styled>User Management</H2Styled>
            </Title>
            <Content>
                <SearchPanel isViewOnly={isViewOnly}/>
                <DataTable
                    data={users}
                    columns={columns}
                    currentPage={pagination.current_page}
                    paginationTotalRows={pagination.total}
                    paginationPerPage={pagination.per_page}
                    lastPage={pagination.last_page}
                    from={pagination.from}
                    to={pagination.to}
                    onChangePage={handleChangePage}
                    onSort={handleSort}
                    pagination={true}
                />
            </Content>
        </PageContentContainer>
    );
};

export default Users;
