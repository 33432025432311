import React, {FC} from 'react';
import {Wrapper, Value} from "./styled";
import {capitalizeFirstLetter} from "../../../../../utils";

type Props = {
    value: string | null
}

const TwoColumnsValue: FC<Props> = ({value}) => {
    const items = value ? value.split(",").map(item => item.trim()) : [];
    const middleIndex = Math.ceil(items.length / 2);
    const firstHalf = items.slice(0, middleIndex);
    const secondHalf = items.slice(middleIndex);

    const prepareValue = (value: string) => {
        return capitalizeFirstLetter(value.replace('&', 'and'));
    }

    return(
        <Wrapper>
            <div>
                {!!firstHalf && firstHalf.map((item) => (
                    <Value key={item}>
                        {prepareValue(`${item}`)}
                    </Value>
                ))}
            </div>
            <div>
                {!!secondHalf && secondHalf.map((item) => (
                    <Value key={item}>
                        {prepareValue(`${item}`)}
                    </Value>
                ))}
            </div>
        </Wrapper>
    );
};

export default TwoColumnsValue;
