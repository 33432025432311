import styled from "styled-components";

export const DefaultTable = styled.div`
  display: grid;
  grid-template-columns: 250px 3fr;

  &>div:last-child  {
    border-bottom: 1px solid #f0f0f0;
  }
`;

export const Content = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding-left: 30px;
`;

export const GroupHeader = styled.h3`
  margin: 0 0 16px 0;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  font-family: ${(props) => props.theme.fontFamilySecondary};

  &:not(:first-child) {
    margin-top: 40px;
  }
`;

export const Table = styled.div`
  display: grid;
  grid-template-columns: 250px 3fr;
`;

export const WideTable = styled(Table)`
  display: grid;
  grid-template-columns: 250px repeat(5, 1fr);
  border-top: 1px solid #F0F0F0;
  
  & >div {
    border-left: 1px solid #F0F0F0;
    border-bottom: 1px solid #F0F0F0;
  }
`;

export const WideTableHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #e0fff4;
  font-size: 16px;
`;

export const TableHeaderGroup = styled.div`
  display: grid;
  grid-template-columns: 400px 400px;
  column-gap: 2px;
`;

export const ComingSoon = styled.span`
  margin-top: 8px;
  font-size: 10px;
  font-style: italic;
  font-weight: 700;
  color: ${(props) => props.theme.greenColor};
`;

export const TitleCell = styled.div<{isForm?: boolean}>`
  min-width: 50px;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 15px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  background-color: #FAFAFA;
  border-top: 1px solid #F0F0F0;
  font-size: 14px;

  ${({isForm}) => isForm && (
      {justifyContent: 'center'}
  )}
`;

export const ValueCell = styled(TitleCell)`
  flex-direction: row;
  min-width: 100px;
  background-color: white;
  border-left: 1px solid #F0F0F0;
  white-space: pre-wrap;
  align-items: center;
`;

export const Value = styled.div`
  font-weight: 500;
  color: #000;
  white-space: pre-wrap;
  line-height: 1;
  word-break: break-all;
`;

export const SubstanceTypeImage = styled(ValueCell)`
  padding: 0;
  display: flex;
  height: 100px;
  width: 100% - 10px;
  border: none;
`;

export const SubstanceImage = styled.img`
  height: 100px;
  cursor: pointer;
  transition: 0.3s;
  object-fit: contain;

  &:hover {
    transform: scale(2.5);
    border: 1px solid lightgray;
    background-color: white;
    z-index: 20;
  }
`;

export const Label = styled.div`
  color: ${(props) => props.theme.greyTextColor};
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
`;

export const Desc = styled.div`
  margin-top: 4px;
  color: ${(props) => props.theme.greyTextColor};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
`;

export const NoData = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
