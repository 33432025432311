import React, {FC, useEffect, useState} from 'react';
interface Props {
    type: string;
    prefix: string;
    alias: string;
    size?: number;
}
const SvgIcon: FC<Props> = ({ type, prefix, alias, size = 66 }) => {
    const [iconExists, setIconExists] = useState(true);

    useEffect(() => {
        if (!document.getElementById(`${type}::${prefix}::${alias}`)) {
            setIconExists(false);
        }
    }, [type, prefix, alias]);

    return (
        <svg width={size} height={size}>
            <use xlinkHref={`#${type}::${prefix}::${iconExists ? alias : 'empty-icon'}`} xlinkTitle={alias} width={size} height={size}/>
        </svg>
    );
}

export default SvgIcon;