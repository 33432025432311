import React, {FC, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/global";
import {fetchUsers, setFilterParams} from "../../../../../store/user/userSlice";
import {Button} from "../../../../controls";
import {ROUTES} from "../../../../../router/routes";
import {
    FormInput,
    FormSingleSelect,
    SearchPanelButtons, SearchPanelButtonsGroup,
    SearchPanelControls,
    SearchPanelWrapper
} from "./styled";

const roles = [
    {value: '', label: 'All Roles'},
    {value: 'admin', label: 'Super Admin'},
    {value: 'staff', label: 'Basic Admin'},
    {value: 'user', label: 'NGO Researcher'},
    {value: 'user-individual', label: 'Individual Researcher'},
]

const statuses = [
    {value: '', label: 'All Statuses'},
    {value: 1, label: 'Active'},
    {value: 0, label: 'Archived'},
]

type Props = {
    isViewOnly?: boolean
}

const SearchPanel: FC<Props> = ({isViewOnly}) => {
    const dispatch = useAppDispatch();
    const didMountRef = useRef(false);
    const location = useLocation();
    const navigate = useNavigate();
    const keepFilters = location.state?.keepFilters || false;
    const [isFilterCollapsed, setIsFilterCollapsed] = useState(true);
    const {listFilters} = useAppSelector(state => state.user);
    const {control, handleSubmit, reset, formState: {errors}} = useForm({
        mode: 'onBlur',
        defaultValues: {
            first_name: listFilters.first_name,
            last_name: listFilters.last_name,
            role: listFilters.role,
            email: listFilters.email,
            is_active: listFilters.is_active,
        }
    });

    const handleReset = () => {
        const data = {
            first_name: '',
            last_name: '',
            role: '',
            email: '',
            is_active: 1,
        }
        reset(data);
        dispatch(setFilterParams({...listFilters, ...data, page: 1}));
    };

    useEffect(() => {
        didMountRef.current = true;
        if (!keepFilters) handleReset();
    }, []);

    useEffect(() => {
        if (didMountRef.current) {
            dispatch(fetchUsers(listFilters));
        }
    }, [listFilters]);

    const handleSearch = (data: any) => {
        dispatch(setFilterParams({...listFilters, ...data, page: 1}));
    };

    return (
        <SearchPanelWrapper>
            <SearchPanelControls>
                <Controller
                    name={"first_name"}
                    control={control}
                    render={({field: {ref, ...field}}) => (
                        <FormInput
                            {...field}
                            label={'First Name'}
                            placeholder={''}
                            error={errors.first_name}
                        />
                    )}
                />
                <Controller
                    name={"last_name"}
                    control={control}
                    render={({field: {ref, ...field}}) => (
                        <FormInput
                            {...field}
                            label={'Last Name'}
                            placeholder={''}
                            error={errors.last_name}
                        />
                    )}
                />
                <Controller
                    name={"email"}
                    control={control}
                    render={({field: {ref, ...field}}) => (
                        <FormInput
                            {...field}
                            label={'Email Address'}
                            placeholder={''}
                            error={errors.email}
                        />
                    )}
                />
                <Controller
                    name={"role"}
                    control={control}
                    render={({field: {ref, ...field}}) => (
                        <FormSingleSelect
                            {...field}
                            label={'User Role'}
                            options={roles}
                            error={errors.role}
                        />
                    )}
                />
                <Controller
                    name={"is_active"}
                    control={control}
                    render={({field: {ref, ...field}}) => (
                        <FormSingleSelect
                            {...field}
                            label={'User Status'}
                            options={statuses}
                            error={errors.is_active}
                        />
                    )}
                />
            </SearchPanelControls>

            <SearchPanelButtons>
                <SearchPanelButtonsGroup>
                    <Button
                        title='SEARCH'
                        color='green'
                        size='LG'
                        variant='secondary'
                        onClick={handleSubmit(handleSearch)}
                    />
                    <Button
                        title='RESET'
                        color='red-transparent'
                        size='LG'
                        variant='secondary'
                        onClick={handleReset}
                    />
                </SearchPanelButtonsGroup>
                {!isViewOnly && (
                    <SearchPanelButtonsGroup>
                        <Button
                            title='ADD NEW USER'
                            color='ultraviolet'
                            size='XXXL'
                            icon={'add'}
                            variant='secondary'
                            onClick={()=>navigate(ROUTES.USER_CREATE)}
                        />
                    </SearchPanelButtonsGroup>
                )}
            </SearchPanelButtons>
        </SearchPanelWrapper>
    );
};

export default SearchPanel;
