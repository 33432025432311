import React, {FC} from 'react';
import {Controller, FieldError} from "react-hook-form";
import {Control} from "react-hook-form/dist/types";
import {IField} from "../../../../../../../types";
import {StyledInput} from "./styled";

type Props = {
    item: IField,
    control: Control<any>
    error: FieldError;
}

const FormInput: FC<Props> = ({item, control, error}) => {
    const {name, rules, attrs, label} = item;
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            {...attrs}
            render={({ field: { ref, ...field } }) => (
                <StyledInput
                    {...field}
                    placeholder={label}
                    value={field.value ?? ''}
                    error={error}
                    inputSize='SM'
                />
            )}
        />
    );
};

export default FormInput;
