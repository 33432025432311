import React, {CSSProperties, FC} from 'react';
import {SpinnerStyled} from "./styled";
import ScaleLoader from "react-spinners/ScaleLoader";
import {Backdrop} from "@mui/material";

interface ISpinner {
    isLoading: boolean;
}

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
};

const Spinner: FC<ISpinner> = ({isLoading}) => {
    return (
        <SpinnerStyled>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            />
            <ScaleLoader
                height={53}
                width={6}
                radius={3}
                margin={3}
                color={'#008D58'}
                loading={isLoading}
                cssOverride={override}
                aria-label="Loading Spinner"
            />
        </SpinnerStyled>
    );
};

export default Spinner;
