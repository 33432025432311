import React, {FC} from 'react';
import {GroupHeader} from "../../Table/styled";
import {ReactComponent as BadgeSurvey} from './../../../../../../assets/images/icons/badge-survey.svg';
import {ReactComponent as BadgePlaci} from './../../../../../../assets/images/icons/badge-placi.svg';
import {ReactComponent as BadgeUnep} from './../../../../../../assets/images/icons/badge-unep.svg';
import {ReactComponent as SpecialChem} from './../../../../../../assets/images/icons/badge-special-chem.svg';
import {Wrapper} from "./styled";
import {IChemical, ICustomFields} from "../../../../../../types";

type Props = {
    chemical: IChemical & {
        survey?: Array<ICustomFields>
        plasi?: Array<ICustomFields>
    } | null
}

const DataSources: FC<Props> = ({chemical}) => {
    return (
        <>
            <GroupHeader>
                Data Sources/(Chemical Identified From)
            </GroupHeader>
            <Wrapper>
                {chemical && chemical?.survey && (
                    <BadgeSurvey/>
                )}
                {chemical && chemical?.plasi && (
                    <BadgePlaci/>
                )}
                <BadgeUnep/>
                {/*<SpecialChem/>*/}
            </Wrapper>
        </>
    )
};

export default DataSources;
