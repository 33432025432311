import React, {FC, useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../hooks/global';
import {Spinner} from "../../common";
import Logo from './../../../assets/images/logo.svg'
import {
    A,
    ButtonWrapper,
    ImgStyled,
    SignUpForm,
    FieldsWrapper,
    FormInput,
    FormSingleSelect,
    Wrapper,
    FormPhoneInput,
    PrivacyPolicy,
    Radios,
    RadiosError
} from "./styled";
import {Controller, useForm, useWatch} from "react-hook-form";
import {Button, Checkbox, Radio} from "../../controls";
import {fetchSignUp} from "../../../store/auth/authSlice";
import {emailPattern} from "../../../helpers/validationRules";
import {fetchCountries, fetchStates} from "../../../store/general/generalSlice";
import {ICountry, ISelectOption, IState, IUserFormData} from "../../../types";
import {ROUTES} from "../../../router/routes";
import {isValidPhoneNumber} from "libphonenumber-js";
import {APP_URI} from "../../../constants/api";

const SignUp: FC = () => {
    const privacyPolicyUrl = `${APP_URI}/privacy-policy.pdf`;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {isLoading} = useAppSelector(state => state.auth);
    const {countries, states} = useAppSelector(state => state.general);
    const {control, handleSubmit, getValues, reset, setValue, formState: {errors, touchedFields, isValid}} = useForm({
        mode: 'onBlur',
        defaultValues: {
            email: '',
            phone: '',
            first_name: '',
            last_name: '',
            country_id: 1,
            state_id: 0,
            province: '',
            city: '',
            organization: '',
            role: '',
            agree: false,
        }
    });

    const countryId = useWatch({
        control,
        name: 'country_id',
    });

    const agree = useWatch({
        control,
        name: 'agree',
    });

    useEffect(() => {
        if (countryId) {
            setValue('state_id', 0);
            setValue('province', '');
        }
    }, [countryId]);

    useEffect(() => {
        dispatch(fetchCountries());
        dispatch(fetchStates());
    }, []);

    const countriesOptions = useMemo(() => {
        return countries ? countries.map((item: ICountry): ISelectOption => ({label: item.title, value: item.id})) : []
    }, [countries]);

    const statesOptions = useMemo(() => {
        const items = states
            ? states.filter((item) => item.country_id === countryId).map((item: IState): ISelectOption => ({label: item.title, value: item.id}))
            : []
        return [{label: 'Select One', value: 0}, ...items];
    }, [states, countryId]);

    const submitButtonStatus = () => {
        return !(!Object.keys(errors).length || isValid) || isLoading || !agree;
    };

    const handleOnSubmit = (data: Omit<IUserFormData, | 'password'>) => {
        dispatch(fetchSignUp({data})).then(resp => {
            if (resp?.type === 'auth/sign-up/fulfilled') {
                reset();
                navigate('/sign-in');
            }
        });
    }

    const handleFormSubmit = () => {
        handleSubmit((data) => handleOnSubmit(data))();
    }

    return (
        <SignUpForm>
            <ImgStyled src={Logo}/>
            <Spinner isLoading={isLoading}/>
            <Wrapper>
                <FieldsWrapper>
                    <Controller
                        name='first_name'
                        control={control}
                        rules={{required: 'This field is required'}}
                        render={({field: {ref, ...field}}) => (
                            <FormInput
                                {...field}
                                label='First Name*'
                                placeholder='First Name'
                                error={errors.first_name}
                            />
                        )}
                    />
                    <Controller
                        name='last_name'
                        control={control}
                        rules={{required: 'This field is required'}}
                        render={({field: {ref, ...field}}) => (
                            <FormInput
                                {...field}
                                label='Last Name*'
                                placeholder='Last Name'
                                error={errors.last_name}
                            />
                        )}
                    />
                    <Controller
                        name='email'
                        control={control}
                        rules={{
                            required: "This field is required",
                            pattern: emailPattern,
                        }}
                        render={({field: {ref, ...field}}) => (
                            <FormInput
                                {...field}
                                label='Email Address*'
                                placeholder='Email Address'
                                error={errors.email}
                            />
                        )}
                    />
                    <Controller
                        name='phone'
                        control={control}
                        rules={{
                            required: "This field is required",
                            validate: (value: string) => isValidPhoneNumber(`+${value}`) || 'Phone number is invalid'
                        }}
                        render={({field: {ref, ...field}}) => (
                            <FormPhoneInput
                                {...field}
                                label='Mobile Phone #*'
                                error={errors.phone}
                            />
                        )}
                    />
                </FieldsWrapper>
                <FieldsWrapper>
                    <Controller
                        name='country_id'
                        control={control}
                        render={({field: {ref, ...field}}) => (
                            <FormSingleSelect
                                {...field}
                                label='Country'
                                options={countriesOptions}
                                error={errors.country_id}
                            />
                        )}
                    />
                    {(countryId === 1 || countryId === 2) && (
                        <Controller
                            name='state_id'
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <FormSingleSelect
                                    {...field}
                                    label='State/Province'
                                    options={statesOptions}
                                    error={errors.state_id}
                                />
                            )}
                        />
                    )}
                    {(countryId !== 1 && countryId !== 2) && (
                        <Controller
                            name='province'
                            control={control}
                            defaultValue={''}
                            render={({field: {ref, ...field}}) => (
                                <FormInput
                                    {...field}
                                    label='State/Province'
                                    placeholder='State/Province'
                                    error={errors.province}
                                />
                            )}
                        />
                    )}
                    <Controller
                        name='city'
                        control={control}
                        render={({field: {ref, ...field}}) => (
                            <FormInput
                                {...field}
                                label='City'
                                placeholder='City'
                                error={errors.city}
                            />
                        )}
                    />
                    <Controller
                        name='organization'
                        control={control}
                        render={({field: {ref, ...field}}) => (
                            <FormInput
                                {...field}
                                label='Organization'
                                placeholder='Organization'
                                error={errors.organization}
                            />
                        )}
                    />
                    <Controller
                        name='role'
                        control={control}
                        rules={{required: 'This field is required'}}
                        render={({field: {ref, ...field}}) => (
                            <>
                                <Radios
                                    row={true}
                                    {...field}
                                >
                                    <Radio
                                        label='NGO Researcher'
                                        value='user'
                                    />
                                    <Radio
                                        label='Individual Researcher'
                                        value='user-individual'
                                    />
                                </Radios>
                                {!!errors.role && (
                                    <RadiosError>
                                        {`${errors.role.message}`}
                                    </RadiosError>
                                )}
                            </>
                        )}
                    />
                </FieldsWrapper>
                <PrivacyPolicy>
                    <Controller
                        name='agree'
                        control={control}
                        render={({field: {ref, ...field}}) => (
                            <Checkbox
                                {...field}
                                label={<span>
                                    I have read and agree to the <A to={privacyPolicyUrl} target="_blank" rel="noreferrer">Privacy Policy</A>
                                </span>}
                            />
                        )}
                    />
                </PrivacyPolicy>
            </Wrapper>
            <ButtonWrapper>
                <Button
                    title='REGISTER TODAY!'
                    onClick={handleFormSubmit}
                    size='XXL'
                    color='green'
                    variant='secondary'
                    disabled={submitButtonStatus()}
                />
            </ButtonWrapper>
            <A to={ROUTES.SIGN_IN}>
                Already have an account? Login
            </A>
        </SignUpForm>
    );
};

export default SignUp;
