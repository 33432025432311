import React, {FC} from 'react';
import BaseModal from "./BaseModal";
import { ReactComponent as SuccessImg } from 'src/assets/images/icons/icon-success-green.svg';
import { ReactComponent as ErrorImg } from 'src/assets/images/icons/icon-error-red.svg';
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {hideAlert} from "../../../store/alert/alertSlice";
import {
    AlertHeader,
    Error,
    Success,
    AlertContent,
    AlertFooter,
    SuccessMessage,
    ErrorMessage,
    ErrorsList,
    ErrorItem, OkBtn,
} from "./styled";

const AlertModal: FC = ({...props}) => {

    const dispatch = useAppDispatch();
    const {isOpened, type, message, errors, cb} = useAppSelector(state => state.alert);

    const handleOnClose = () => {
        dispatch(hideAlert());
        if (cb) cb();
    }

    return (
        <BaseModal
            isOpen={isOpened}
            onClose={handleOnClose}
            maxWidth={400}
            {...props}
        >
            <AlertHeader>
                {type === 'success' && (
                    <>
                        <SuccessImg />
                        <Success>Success</Success>
                    </>

                )}
                {type === 'error' && (
                    <>
                        <ErrorImg />
                        <Error>Error</Error>
                    </>
                )}
            </AlertHeader>
            <AlertContent>
                {type === 'success' && (
                    <SuccessMessage dangerouslySetInnerHTML={{__html: message}}/>
                )}
                {type === 'error' && (
                    <>
                        <ErrorMessage dangerouslySetInnerHTML={{__html: message}}/>
                        {errors && (
                            <ErrorsList>
                                {errors.map((item: string, key: number) => (
                                <ErrorItem key={key}>
                                    {item}
                                </ErrorItem>
                            ))}
                            </ErrorsList>
                        )}
                    </>
                )}
            </AlertContent>
            <AlertFooter>
                <OkBtn
                    onClick={handleOnClose}
                    color={'green'}
                    title={'OK'}
                    size='LG'
                />
            </AlertFooter>
        </BaseModal>
    );
};

export default AlertModal;
