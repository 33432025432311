import React, {FC} from 'react';
import {Copyright, FormWrapper, PageContainer, Wrapper} from "./styled";
import moment from "moment";

type Props = {
    children: React.ReactNode;
    isCopyright?: boolean;
};

const SimpleLayout: FC<Props> = ({isCopyright = true, children}) => {
    return (
        <Wrapper>
            <PageContainer>
                <FormWrapper>
                    <div/>
                    {children}
                    {isCopyright && (
                        <Copyright>© {moment().format('YYYY')} ICCA. All rights reserved.</Copyright>
                    )}
                </FormWrapper>
            </PageContainer>
        </Wrapper>
    );
};

export default SimpleLayout;
