import styled from "styled-components";
import {Link} from "react-router-dom";

export const Title = styled.div`
  margin-bottom: 27px;
  border-bottom: 1px solid #E4E4E4;
`;

export const Content = styled.div`
`;

export const H2Styled = styled.h2`
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  font-family: ${(props) => props.theme.fontFamilySecondary};
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.blueColor}
`;
