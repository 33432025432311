import React, {FC} from 'react';
import {Cell, Row, Table, ComeSoon} from "./styled";
import {GroupHeader} from "../Table/styled";

type Props = {
    casrn: string
}

const Risk: FC<Props> = ({casrn}) => {
    const elementEmpty = [
        [true,  'Derived no-effect level (DNEL)', 'Value (mg/kg)', 'Source'],
        [false, 'General Population, Oral, Long Term Systemic', '--', '--'],
        [false, 'General Population, Inhalation, Long Term Systemic', '--', '--'],
        [false, 'Worker, Inhalation, Long Term Systemic', '--', '--'],

        [true,  'Predicted no-effect concentration (PNEC)', 'Value (mg/kg)', 'Notes'],
        [false, 'Freshwater, mg/L', '--', '--'],
        [false, 'Marine, mg/L', '--', '--'],
        [false, 'Sediment (freshwater), mg/kg sediment dw', '--', '--'],
        [false, 'Sediment (marine), mg/kg sediment dw', '--', '--'],
        [false, 'Soil', '--', '--'],

        [true,  'Alternative Threshold Data', 'Value (mg/kg/day)', 'Source'],
        [false, 'Oral Equivalent RfD (mg/kg-day)', '--', '--'],
        [false, 'Oral Equivalent TDI (mg/kg-day)', '--', '--'],
        [false, 'Statistical PoD (mg/kg-day)', '--', '--'],

        [true,  'Modeled Toxicological Threshold of Concern (TTC)', 'Value (1-3)', 'Description'],
        [false, 'Cramer Class', '--', '--'],

        [true,  'Hazard Assessments', '', 'Source'],
        [false, 'No Hazard Identified (General Population O/I, W I)', '--', '--'],
        [false, 'Presence of Quantitative Hazard Threshold OR Conclusion of Low Hazard (No Threshold)', '--', '--'],
    ];

    const element31570044 = [
        [true,  'Derived no-effect level (DNEL)', 'Value (mg/kg)', 'Source'],
        [false, 'General Population, Oral, Long Term Systemic', '0.06', 'ECHA'],
        [false, 'General Population, Inhalation, Long Term Systemic', '0.029', 'ECHA'],
        [false, 'Worker, Inhalation, Long Term Systemic', '0.39', 'ECHA'],

        [true,  'Predicted no-effect concentration (PNEC)', 'Value (mg/kg)', 'Notes'],
        [false, 'Freshwater, mg/L', '--', 'Not available due to low water solubility. ECHA reports "no hazard identified"'],
        [false, 'Marine, mg/L', '--', 'Not available due to low water solubility. ECHA reports "no hazard identified"'],
        [false, 'Sediment (freshwater), mg/kg sediment dw', '100', 'Assessment factor of 10 applied by ECHA'],
        [false, 'Sediment (marine), mg/kg sediment dw', '20', 'Assessment factor of 10 applied by ECHA'],
        [false, 'Soil', '13', 'Assessment factor of 10 applied by ECHA'],

        [true,  'Alternative Threshold Data', 'Value (mg/kg/day)', 'Source'],
        [false, 'Oral Equivalent RfD (mg/kg-day)', 'NA', 'US EPA Comptox Dashboard'],
        [false, 'Oral Equivalent TDI (mg/kg-day)', 'NA', 'US EPA Comptox Dashboard'],
        [false, 'Statistical PoD (mg/kg-day)', '34.3', 'US EPA Comptox Dashboard'],

        [true,  'Modeled Toxicological Threshold of Concern (TTC)', 'Value (1-3)', 'Description'],
        [false, 'Cramer Class', '3', 'Cramer Class 3 are substances with chemical structures that permit no strong initial presumption of safety or may even suggest significant toxicity or have reactive functional groups'],

        [true,  'Hazard Assessments', '', 'Source'],
        [false, 'No Hazard Identified (General Population O/I, W I)', 'N/A', '[Source: ECHA REACH]'],
        [false, 'Presence of Quantitative Hazard Threshold OR Conclusion of Low Hazard (No Threshold)', 'Yes', '[Source: ECHA REACH]'],
    ];

    const element117817 = [
        [true,  'Derived no-effect level (DNEL)', 'Value (mg/kg)', 'Source'],
        [false, 'General Population, Oral, Long Term Systemic', '0.036', 'ECHA'],
        [false, 'General Population, Inhalation, Long Term Systemic', '0.037', 'ECHA'],
        [false, 'Worker, Inhalation, Long Term Systemic', '0.15', 'ECHA'],

        [true,  'Predicted no-effect concentration (PNEC)', 'Value (mg/kg)', 'Notes'],
        [false, 'Freshwater, mg/L', '--', 'No hazard identified'],
        [false, 'Marine, mg/L', '--', 'No hazard identified'],
        [false, 'Sediment (freshwater), mg/kg sediment dw', '--', 'No hazard identified'],
        [false, 'Sediment (marine), mg/kg sediment dw', '--', 'No hazard identified'],
        [false, 'Soil', '--', 'No hazard identified'],

        [true,  'Alternative Threshold Data', 'Value (mg/kg/day)', 'Source'],
        [false, 'Oral Equivalent RfD (mg/kg-day)', '0.02', 'US EPA Comptox Dashboard'],
        [false, 'Oral Equivalent TDI (mg/kg-day)', '0.05', 'US EPA Comptox Dashboard'],
        [false, 'Statistical PoD (mg/kg-day)', '0.02', 'US EPA Comptox Dashboard'],

        [true,  'Modeled Toxicological Threshold of Concern (TTC)', 'Value', 'Description'],
        [false, 'Cramer Class', '1', 'Cramer Class 1 are substances of simple chemical structure with known metabolic pathways and are of low potential toxicity'],

        [true,  'Hazard Assessments', '', 'Source'],
        [false, 'No Hazard Identified (General Population O/I, W I)', 'N/A', '[Source: ECHA REACH]'],
        [false, 'Presence of Quantitative Hazard Threshold OR Conclusion of Low Hazard (No Threshold)', 'Yes', '[Source: ECHA REACH]'],
    ];

    const element26658195 = [
        [true,  'Derived no-effect level (DNEL)', 'Value (mg/kg)', 'Source'],
        [false, 'General Population, Oral, Long Term Systemic', 'NA', 'ECHA'],
        [false, 'General Population, Inhalation, Long Term Systemic', 'NA', 'ECHA'],
        [false, 'Worker, Inhalation, Long Term Systemic', 'NA', 'ECHA'],

        [true,  'Predicted no-effect concentration (PNEC)', 'Value (mg/kg)', 'Notes'],
        [false, 'Freshwater, mg/L', '0.32', 'Assessment factor of 50 applied by ECHA'],
        [false, 'Marine, mg/L', '0.032', 'Assessment factor of 500 applied by ECHA'],
        [false, 'Sediment (freshwater), mg/kg sediment dw', '1.141', 'Assessment factor of 1000 applied by ECHA'],
        [false, 'Sediment (marine), mg/kg sediment dw', '1.141', 'Assessment factor of 1000 applied by ECHA'],
        [false, 'Soil', '--', 'No hazard identified'],

        [true,  'Alternative Threshold Data', 'Value (mg/kg/day)', 'Source'],
        [false, 'Oral Equivalent RfD (mg/kg-day)', 'NA', 'US EPA Comptox Dashboard'],
        [false, 'Oral Equivalent TDI (mg/kg-day)', 'NA', 'US EPA Comptox Dashboard'],
        [false, 'Statistical PoD (mg/kg-day)', '277.45', 'Aurisano et al (2016)'],

        [true,  'Modeled Toxicological Threshold of Concern (TTC)', 'Value (1-3)', 'Description'],
        [false, 'Cramer Class', '2', 'Cramer Class 2 are Substances which possess structures that are less innocuous than Class I substances, but do not contain structural features suggestive of toxicity like those substances in Class III.'],

        [true,  'Hazard Assessments', '', 'Source'],
        [false, 'No Hazard Identified (General Population O/I, W I)', 'Yes', '[Source: ECHA REACH]'],
        [false, 'Presence of Quantitative Hazard Threshold OR Conclusion of Low Hazard (No Threshold)', 'Yes', '[Source: ECHA REACH]'],
    ];

    let items = elementEmpty;
    if (casrn === '31570-04-4') items = element31570044;
    else if (casrn === '117-81-7') items = element117817;
    else if (casrn === '26658-19-5') items = element26658195;

    const TableRow = (({items, isHeader = false}: {items: Array<any>, isHeader?: boolean}) => {
        return (
            <Row isHeader={isHeader}>
                <Cell flex={2}>
                    {items[0]}
                </Cell>
                <Cell flex={1}>
                    {items[1]}
                </Cell>
                <Cell flex={1}>
                    {items[2]}
                </Cell>
            </Row>
        );
    });

    return (
        <>
            <GroupHeader>Threshold Values</GroupHeader>
            <Table>
                <>
                    {items.map(([isHeader, ...item], idx) => (
                        <TableRow
                            key={idx}
                            items={item}
                            isHeader={!!isHeader}
                        />
                    ))}
                </>
                <ComeSoon>MORE TO COME SOON</ComeSoon>
            </Table>
        </>
    )
};

export default Risk;
