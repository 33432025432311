import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 20px 0 50px 0;
`;

export const Text = styled.p`
  font-size: 14px;
  margin-right: 25px;
`;
