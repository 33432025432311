import styled from "styled-components";

export const Title = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 27px;
  padding-bottom: 16px;
  border-bottom: 1px solid #E4E4E4;
`;

export const Content = styled.div`
  display: flex;
`;

export const H2Styled = styled.h2<{$isViewOnly?: boolean}>`
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  font-family: ${(props) => props.theme.fontFamilySecondary};
  max-width: ${(props) => props.$isViewOnly ? '1100px;' : '850px;'};
  word-break: break-all;
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
`;

export const Label = styled.div`
  color: ${(props) => props.theme.greyTextColor};
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
`;
