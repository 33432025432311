import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const Content = styled.div<{$isAside?: boolean}>`
  padding: 28px 28px 60px 28px;
  background-color: white;
  width: 100%;
  border-radius: 2px;

    ${({$isAside}) => $isAside && {
        borderTopLeftRadius: '0',
        borderBottomLeftRadius: '0'
    }}
`;
