import React, {FC, useEffect, useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {ICountry, ISelectOption, IState, IUserFormData} from "../../../types";
import {useNavigate} from "react-router-dom";
import {Controller, useForm, useWatch} from "react-hook-form";
import {fetchCountries, fetchStates} from "../../../store/general/generalSlice";
import {fetchLogout, fetchUpdateProfile, fetchUser, fetchUserDelete} from "../../../store/auth/authSlice";
import {ConfirmationModal, PageContentContainer} from "../../common";
import {
    ButtonWrapper,
    Content,
    FieldsWrapper,
    FormPhoneInput,
    FormSingleSelect,
    H2Styled,
    Title,
    Wrapper
} from "./styled";
import {emailPattern, passwordPattern} from "../../../helpers/validationRules";
import {isValidPhoneNumber} from "libphonenumber-js";
import {Button, Input} from "../../controls";
import {FormInput} from "../Chemicals/parts/SearchPanel/styled";

const Account: FC = () => {
    const breadcrumbs = [
        {
            link: '/account',
            name: 'My Profile',
        }
    ]

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {isLoading} = useAppSelector(state => state.auth);
    const {user} = useAppSelector(state => state.auth);
    const {countries, states} = useAppSelector(state => state.general);
    const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
    const {control, handleSubmit, getValues, reset, setValue, formState: {errors, touchedFields, isValid}} = useForm({
        mode: 'onBlur',
        defaultValues: {
            email: '',
            phone: '',
            first_name: '',
            last_name: '',
            country_id: 1,
            state_id: 0,
            province: '',
            city: '',
            organization: '',
            function: '',
            password: '',
            password_confirm: '',
        }
    });

    useEffect(() => {
        reset({
            email: user?.email || '',
            phone: user?.address.phone || '',
            first_name: user?.profile.first_name || '',
            last_name: user?.profile.last_name || '',
            country_id: user?.address.country_id || 1,
            state_id: user?.address.state_id || 0,
            province: user?.address.province || '',
            city: user?.address.city || '',
            organization: user?.profile.organization || '',
            function: user?.profile.function || '',
        });
    }, [user]);

    const countryId = useWatch({
        control,
        name: 'country_id',
    });

    useEffect(() => {
        if (countryId) {
            setValue('state_id', 0);
            setValue('province', '');
        }
    }, [countryId]);

    useEffect(() => {
        dispatch(fetchCountries());
        dispatch(fetchStates());
        dispatch(fetchUser());
    }, []);

    const countriesOptions = useMemo(() => {
        return countries ? countries.map((item: ICountry): ISelectOption => ({label: item.title, value: item.id})) : []
    }, [countries]);

    const statesOptions = useMemo(() => {
        const items = states
            ? states.filter((item) => item.country_id === countryId).map((item: IState): ISelectOption => ({label: item.title, value: item.id}))
            : []
        return [{label: 'Select One', value: 0}, ...items];
    }, [states, countryId]);

    const handleOnSubmit = (data: Omit<IUserFormData, 'role' | 'password'>) => {
        dispatch(fetchUpdateProfile({data})).then(resp => {
            // if (resp?.type === 'auth/update-profile/fulfilled') {
            //     reset();
            //     navigate('/sign-in');
            // }
        });
    }

    const handleUserDelete = () => {
        setIsDeleteModalShown(false)
        dispatch(fetchUserDelete()).then(resp => {
            dispatch(fetchLogout()).then(() => navigate('/sign-in'));
        });
    }

    const handleFormSubmit = () => {
        handleSubmit((data) => handleOnSubmit(data))();
    }

    return (
        <PageContentContainer title={'Glossary of Terms'} isLoading={isLoading} breadcrumbs={breadcrumbs}>
            <Title>
                <H2Styled>My Profile</H2Styled>
            </Title>
            <Content>
                <Wrapper>
                    <FieldsWrapper>
                        <Controller
                            name='first_name'
                            control={control}
                            rules={{required: 'This field is required'}}
                            render={({field: {ref, ...field}}) => (
                                <FormInput
                                    {...field}
                                    label='First Name*'
                                    placeholder='First Name'
                                    error={errors.first_name}
                                />
                            )}
                        />
                        <Controller
                            name='last_name'
                            control={control}
                            rules={{required: 'This field is required'}}
                            render={({field: {ref, ...field}}) => (
                                <FormInput
                                    {...field}
                                    label='Last Name*'
                                    placeholder='Last Name'
                                    error={errors.last_name}
                                />
                            )}
                        />
                        <Controller
                            name='email'
                            control={control}
                            rules={{
                                required: "This field is required",
                                pattern: emailPattern,
                            }}
                            render={({field: {ref, ...field}}) => (
                                <FormInput
                                    {...field}
                                    label='Email Address*'
                                    placeholder='Email Address'
                                    error={errors.email}
                                />
                            )}
                        />
                    </FieldsWrapper>
                    <FieldsWrapper>
                        <Controller
                            name='phone'
                            control={control}
                            rules={{
                                required: "This field is required",
                                validate: (value: string) => isValidPhoneNumber(`${value}`) || 'Phone number is invalid'
                            }}
                            render={({field: {ref, ...field}}) => (
                                <FormPhoneInput
                                    {...field}
                                    label='Mobile Phone #*'
                                    error={errors.phone}
                                />
                            )}
                        />
                        <Controller
                            name='country_id'
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <FormSingleSelect
                                    {...field}
                                    label='Country'
                                    options={countriesOptions}
                                    error={errors.country_id}
                                />
                            )}
                        />
                        {(countryId === 1 || countryId === 2) && (
                            <Controller
                                name='state_id'
                                control={control}
                                render={({field: {ref, ...field}}) => (
                                    <FormSingleSelect
                                        {...field}
                                        label='State/Province'
                                        options={statesOptions}
                                        error={errors.state_id}
                                    />
                                )}
                            />
                        )}
                        {(countryId !== 1 && countryId !== 2) && (
                            <Controller
                                name='province'
                                control={control}
                                defaultValue={''}
                                render={({field: {ref, ...field}}) => (
                                    <FormInput
                                        {...field}
                                        label='State/Province'
                                        placeholder='State/Province'
                                        error={errors.province}
                                    />
                                )}
                            />
                        )}
                    </FieldsWrapper>
                    <FieldsWrapper>
                        <Controller
                            name='city'
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <FormInput
                                    {...field}
                                    label='City'
                                    placeholder='City'
                                    error={errors.city}
                                />
                            )}
                        />
                        <Controller
                            name='organization'
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <FormInput
                                    {...field}
                                    label='Organization'
                                    placeholder='Organization'
                                    error={errors.organization}
                                />
                            )}
                        />
                        {/*<Controller*/}
                        {/*    name='function'*/}
                        {/*    control={control}*/}
                        {/*    render={({field: {ref, ...field}}) => (*/}
                        {/*        <FormInput*/}
                        {/*            {...field}*/}
                        {/*            label='Role/Function'*/}
                        {/*            placeholder='Role/Function'*/}
                        {/*            error={errors.function}*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/*/>*/}
                    </FieldsWrapper>
                    <FieldsWrapper>
                        <Controller
                            name={"password"}
                            control={control}
                            rules={{
                                pattern: passwordPattern
                            }}
                            render={({ field: { ref, ...field } }) => (
                                <FormInput
                                    {...field}
                                    label={'Password'}
                                    type={'password'}
                                    placeholder={'Enter Password'}
                                    inputSize='XL'
                                    error={errors.password}
                                />
                            )}
                        />
                        <Controller
                            name={"password_confirm"}
                            control={control}
                            rules={{
                                validate: (value) => {
                                    return value === getValues('password') ? true : 'Passwords should be the same';
                                }
                            }}
                            render={({ field: { ref, ...field } }) => (
                                <FormInput
                                    {...field}
                                    label={'Confirm Password'}
                                    type={'password'}
                                    placeholder={'Confirm Password'}
                                    inputSize='XL'
                                    error={errors.password_confirm}
                                />
                            )}
                        />
                    </FieldsWrapper>
                </Wrapper>
                <ButtonWrapper>
                    <Button
                        title='Update'
                        onClick={handleFormSubmit}
                        color='green'
                        variant='secondary'
                    />
                    <Button
                        title='Delete Account'
                        onClick={() => setIsDeleteModalShown(true)}
                        variant='secondary'
                        color='errorColor'
                    />
                </ButtonWrapper>
            </Content>
            <ConfirmationModal
                isOpen={isDeleteModalShown}
                onClose={() => setIsDeleteModalShown(false)}
                onConfirm={() => handleUserDelete()}
                text={'Do you really want to delete your account? This action cannot be undone.'}
            />
        </PageContentContainer>
    );
};

export default Account;
