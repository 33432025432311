import React, {FC} from 'react';
import {EvidenceText, EvidenceWrapper, Rollover} from "./styled";
import {IField} from "../../../../../types";

type Props = {
    item: IField
}

const Evidence: FC<Props> = ({item}) => {
    const value = parseFloat(item.value as string) ?? null;

    if (item.is_evidence_translate && value !== null) {
        if (value < 0.5) {
            return (
                <EvidenceWrapper>
                    <EvidenceText style={{color: '#dc661e'}}>No Evidence</EvidenceText>
                    <Rollover>No current classification, no identified studies</Rollover>
                </EvidenceWrapper>
            );
        } else if (value === 0.5) {
            return (
                <EvidenceWrapper>
                    <EvidenceText style={{color: '#004994'}}>Some Evidence</EvidenceText>
                    <Rollover>Some classification but not wide agreement</Rollover>
                </EvidenceWrapper>
            );
        } else if (value > 0.5) {
            return (
                <EvidenceWrapper>
                    <EvidenceText style={{color: '#008d58'}}>Sufficient Evidence</EvidenceText>
                    <Rollover>Wide agreement on classification</Rollover>
                </EvidenceWrapper>
            )
        }
    }

    return (
        <EvidenceWrapper>
            <EvidenceText style={{color: '#7F7F7F'}}>Not Classified</EvidenceText>
        </EvidenceWrapper>
    )
};


export default Evidence;
