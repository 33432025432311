export const consoleHandler = () => {
  const originalConsoleError = console.error;
  console.error = (message: string, ...arg: Array<any>) => {
    if (
      message.includes('prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute')
    )
      return;
    originalConsoleError.call(console, message, ...arg);
  };
};
