import React, {FC} from 'react';
import {Icon, IconsGroup, IconsWrapper, IconTitle, IconWrapper, NoTitle} from "./styled";
import {GroupHeader} from "../../Table/styled";
import {SvgIcon} from "../../../../../common";
import {IChemicalOption} from "../../../../../../types";

type Props = {
    type: 'function' | 'is' | 'polymer'
    items: Array<IChemicalOption>
    flex?: number
    grow?: number
}

const IconComponent = ({item, type}: { item: IChemicalOption, type: 'function' | 'is' | 'polymer' }) => {
    return (
        <IconWrapper>
            <Icon type={item.type}>
                <SvgIcon
                    type={type}
                    prefix={item.type}
                    alias={item.alias}
                />
            </Icon>
            <IconTitle type={type}>
                {item.title}
            </IconTitle>
        </IconWrapper>
    );
}

const Icons: FC<Props> = ({type, items = [], flex = 1, grow = 1 }) => {
    return (
        <IconsWrapper $flex={flex} $grow={grow}>
            <GroupHeader>
                {type === 'function' ? 'Functions' : (type === 'is' ? 'Uses' : 'Polymers')}
            </GroupHeader>
            {items && !!items.length ? (
                <IconsGroup>
                    {items.map((item) => (
                        <IconComponent
                            type={type}
                            key={`${item.id}_${item.alias}`}
                            item={item}
                        />
                    ))}
                </IconsGroup>
            ) : (
                <NoTitle>
                    There are no associated {type === 'function' ? 'functions' : (type === 'is' ? 'uses' : 'polymer')}
                </NoTitle>
            )}
        </IconsWrapper>
    )
};

export default Icons;
