import styled from "styled-components";
import {Input} from "../../../../../../controls";

export const StyledInput = styled(Input)`
  width: 100%;
  
  & input {
    font-size: 14px;  
  }
`;
