import React, {FC, useEffect, useRef, useState} from 'react';
import moment from "moment";
import {PageContent, PageContentContainer} from "../../common";
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {fetchHistory, fetchUpload} from "../../../store/import/importSlice";
import {capitalizeFirstLetter} from "../../../helpers/commonHelpers";
import {Input} from "../../controls";
import {fileDownload} from "../../../store/general/generalSlice";
import {defaultTheme} from "../../../themes/main";
import {
    customDataTableStyles, H2Styled,
    LabelStyled,
    PreviousImport,
    Row,
    StyledDataTable,
    TemplateLink,
    Title,
    UploadButton,
    Wrapper
} from "./styled";

const Import: FC = () => {
    const breadcrumbs = [
        {
            link: '/import',
            name: 'Data Import',
        }
    ]

    const columns = [
        {
            name: 'Filename',
            alias: 'file_title',
            selector: (row: any) => row.file_title,
            sortable: false,
            cell: (row: any) =><div style={{cursor: "pointer"}} onClick={()=>{
                dispatch(fileDownload({file: row.full_file_path, title: row.file_title}));
            }}>{row.file_title}</div>,
            style: {
                color: defaultTheme.blueColor,
            }
        },
        {
            name: 'Date Uploaded',
            alias: 'created_at',
            selector: (row: any) => moment(row.created_at).format('MM/DD/YYYY hh:mm A'),
            sortable: false,
            width: '170px',
            wrap: true,
        },
        {
            name: 'Uploaded By',
            alias: 'uploaded_by',
            selector: (row: any) => `${row?.user?.profile.first_name ?? ''} ${row?.user?.profile.last_name ?? ''}`,
            sortable: false,
            width: '160px',
            wrap: true,
        },
        {
            name: 'Status',
            alias: 'status',
            cell: (row: any) => <span style={{color: row.status === 'success' ? 'green' : 'red'}}>{capitalizeFirstLetter(row.status)}</span>,
            sortable: false,
            width: '90px',
        },
    ];

    const dispatch = useAppDispatch();
    const {history, isLoading} = useAppSelector(state => state.import);
    const [fileTitle, setFileTitle] = useState('');
    const hiddenFileInput = useRef<any>(null);

    useEffect(() => {
        dispatch(fetchHistory());
    }, []);

    const handleFileUpload = (e: any) => {
        const file = e.target.files[0] || null;
        if (file) {
            setFileTitle(file.name);
            dispatch(fetchUpload(file)).then(resp => {
                setFileTitle('');
            });
        }
        e.target.value = '';
    }

    const handleUploadClick = (event: any) => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    return (
        <PageContentContainer title={'Data Import'} isLoading={isLoading} breadcrumbs={breadcrumbs}>
            <Title>
                <H2Styled>Data Import</H2Styled>
            </Title>
            <PageContent>
                <Wrapper>
                    <Row>
                        <LabelStyled>Import Chemical Data</LabelStyled>
                        <Input inputSize={'SM'} value={fileTitle || ''} readOnly/>
                        <UploadButton title={'BROWSE & UPLOAD'} size={'XL'} color={'blue'} onClick={handleUploadClick}/>
                        <input
                            type="file"
                            ref={hiddenFileInput}
                            onChange={handleFileUpload}
                            style={{display:'none'}}
                            accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
                        />
                        <TemplateLink href={'/import-template.xlsx'} target={'_blank'} rel={'noreferrer'}>Download Template</TemplateLink>
                    </Row>
                    <Row>
                        <PreviousImport>Previous UNEP Chemical/Additives Uploads</PreviousImport>
                    </Row>
                    <Row>
                        <StyledDataTable
                            userStyles={customDataTableStyles}
                            data={history}
                            columns={columns}
                            pagination={false}
                        />
                    </Row>
                </Wrapper>
            </PageContent>
        </PageContentContainer>
    );
};

export default Import;
