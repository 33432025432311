import styled from "styled-components";

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

export const Row = styled.div<{isHeader?: boolean}>`
  display: flex;
  padding: 15px;
  flex-direction: row;
  gap: 10px;

  background-color: ${(props) => props?.isHeader ? '#e1e1e1' : '#fff'};
  font-weight: ${(props) => props?.isHeader ? '600' : 'normal'};
  font-size: ${(props) => props?.isHeader ? '16px' : '14px'};
  color: ${(props) => props?.isHeader ? '#7f7f7f' : 'black'};
  
  & div:first-child {
    margin-left: ${(props) => props?.isHeader ? 0 : '10px'};
  }
`;

export const Cell = styled.div<{flex?: number}>`
  ${({flex}) => (
    'flex: ' + flex
  )}
`;

export const ComeSoon = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  background-color: #e1e1e1;
  padding: 15px;
  color: #7f7f7f
`;