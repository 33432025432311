import React from 'react';
import * as d3 from 'd3';
import Datamaps from 'datamaps';
import './styles.css'

const MAP_CLEARING_PROPS = [
    'height', 'scope', 'setProjection', 'width'
];

interface Props {
    countries: Array<string>
    regions: Array<Region>
    arc?: any[];
    arcOptions?: DataMapArcConfigOptions;
    attacks?: any[];
    bubbleOptions?: DataMapBubblesConfigOptions;
    bubbles?: any;
    graticule?: boolean;
    height?:  null | number | string;
    labels?: boolean;
    responsive?: boolean;
    style?: any;
    updateChoroplethOptions?: any;
    width?: null | number | string;
    geographyConfig?: DataMapGeographyConfigOptions;
    fills?: any;
    setProjection?: (element: HTMLElement, options: DataMapOptions) => DataMapProjection;
    done?: (datamap: {
        svg: d3.Selection<any>,
        options: DataMapOptions,
        path: d3.geo.Path;
        projection: d3.geo.Projection;
    }) => void;
    restProps?: any
}

type Region = {
    pos: number,
    alias: Array<string>,
    title: string,
    codes: Array<string>,
    icon: string
}

const propChangeRequiresMapClear = (oldProps: any, newProps: any) => {
    return MAP_CLEARING_PROPS.some((key: any) =>
        oldProps[key] !== newProps[key]
    );
};

let map: any;
let regions: Array<Region> = [];

const Map: React.FC<Props> = (props) => {
    regions = props.regions;
    const containerRef =  React.useRef<HTMLDivElement>(null);
    const prevProps = React.useRef(props);

    const renderHoverInfo = (geo:any, data:any) => {
        const region = regions.sort((a, b) => a.pos - b.pos).find((item: Region) => item.codes.includes(geo.id));
        return data && [
            '<div class="hoverinfo" style="display: flex; align-items: center; justify-content: center; gap: 8px; padding: 6px 12px; border-radius: 8px; background-color: #f8f3eb">',
            '<img src="'+region?.icon+'" alt=""/>',
            '<strong>',
            geo.properties.name,
            '</strong>',
            '</div>'
        ].join('')
    }

    const clear = () => {
        const container = containerRef.current;
        if (container) {
            for (const child of Array.from(container.childNodes)) {
                container.removeChild(child);
            }
            map = null;
        }
    }

    const drawMap = () => {
        const {
            countries = [],
            arc,
            arcOptions,
            attacks,
            bubbles,
            bubbleOptions,
            graticule,
            labels,
            updateChoroplethOptions,
            ...restProps
        } = props;

        const data = countries.map(item => ({[item]: {fillKey: 'selected', fill: '#6e94f6'}}))
            .reduce((acc, obj) => {
                for (const key in obj) {
                    acc[key] = obj[key];
                }
                return acc;
            }, {});

        if (!map) {
            map = new Datamaps({
                ...restProps,
                data,
                element: containerRef.current,
                setProjection: function(element: any) {
                    // const projection = d3.geo.equirectangular()
                    const projection = d3.geo.mercator()
                        .center([0, 0])
                        .rotate([0, 0, 0])
                        .scale((Math.min(element.offsetWidth / 1.5 / Math.PI, element.offsetHeight / 1.5 / Math.PI)))
                        .translate([element.offsetWidth / 2, (element.offsetHeight / 1.5)]);
                    const path = d3.geo.path().projection(projection);
                    return {path: path, projection: projection};
                },
                geographyConfig: {
                    popupOnHover: true,
                    highlightOnHover: true,
                    borderWidth: 0.5,
                    highlightFillColor: (data: any) => data.fillKey ? '#ffb500' : '#436970',
                    highlightBorderColor: (data: any) => data.fillKey ? '#fff' : '#fff',
                    highlightBorderWidth: (data: any) => data.fillKey ? 1 : 0.5,
                    popupTemplate: renderHoverInfo,
                },
                fills: {
                    selected: '#ffb500',
                    defaultFill: '#436970'
                },
            });

            map.legend({
                // legendTitle: "",
                defaultFillName: "Registered in this country",
                labels: {
                    selected: "Not registered in this country or no information",
                }
            });
        } else {
            map.updateChoropleth(data, updateChoroplethOptions);
        }

        if (arc) {
            map.arc(arc, arcOptions);
        }

        if (bubbles) {
            map.bubbles(bubbles, bubbleOptions);
        }

        if (graticule) {
            map.graticule();
        }

        if (labels) {
            map.labels();
        }

        if (attacks) {
            map.handleArcsAdvance(attacks,{
                arcSharpness: 1.4,
                greatArc: true,
                animationSpeed: 600,
            });
        }
    }

    const resizeMap = () => {
        map.resize();
    }

    React.useEffect(() => {
        if (props.responsive) {
            window.addEventListener('resize', resizeMap);
        }
        drawMap();
    },[]);

    React.useEffect(() => {
        if (propChangeRequiresMapClear(prevProps.current, props)) {
            clear();
        }
        prevProps.current = props;
    }, [props]);

    React.useEffect(() => {
        drawMap();
    });

    React.useEffect(() => {
        return () => {
            clear();
            if (props.responsive) {
                window.removeEventListener('resize', resizeMap);
            }
            map = null;
        }
    }, []);

    return (
        <div ref={containerRef} style={{
            width: '100%',
            ...props.style
        }} />
    );
}

export default Map;
