import styled from "styled-components";

export const IconsWrapper = styled.div<{$flex: number, $grow: number}>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => props.$flex};
  flex-grow: ${(props) => props.$grow};
  width: 100%;
`;

export const IconsGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

export const Icon = styled.div<{type: 'survey' | 'unep' | 'both'}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 66px;
  height: 66px;
  background: ${(props) => props.type === 'unep' 
    ? 'none' 
    : 'linear-gradient(180deg, #FDD774 0%, #D3AC45 100%)'
}`;

export const IconTitle = styled.div<{type: 'function' | 'is' | 'polymer'}>`
  display: flex;
  overflow-wrap: break-word;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 11px;
  min-height: 34px;
  color: ${(props) => props.theme.blueColor};
`;

export const IconWrapper = styled.div`
  padding: 0;
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: center;
  max-width: 66px;
`;

export const NoTitle = styled.span`
  width: 100%;
  display: flex;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
`;
