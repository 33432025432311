import React, {FC} from 'react';
import {RadioStyled} from './styled';
import {CheckboxProps} from "@mui/material/Checkbox/Checkbox";
import {FormControlLabel} from "@mui/material";
import {defaultTheme} from "../../../themes/main";

interface Props extends CheckboxProps {
    name?: string;
    label?: string;
}

const Radio: FC<Props> = ({label = '', ...props}) => {
    return (
        <FormControlLabel
            control={
                <RadioStyled
                    sx={{
                        color: '#E4E4E4',
                        '&.Mui-checked': {
                            color: defaultTheme.terracottaColor,
                        },
                    }}
                    {...props}
                />
            }
            sx={{
                '& .MuiTypography-root': {
                    fontFamily: defaultTheme.fontFamily,
                    fontSize: '14px'
                },
            }}
            label={label}
        />
    );
};

export default Radio;
