import styled from "styled-components";

export const LabelStyled = styled.label`
  display: flex;
  margin: 0 10px 10px 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #7F7F7F;

  @media screen and (max-width: 1110px){
    display: none;
  }
`;
