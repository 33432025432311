import styled from "styled-components";
import {Link} from "react-router-dom";

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 440px;
  background-color: white;
  box-sizing: content-box;
  padding: 30px;
  margin-top: 50px;
`;

export const ImgStyled = styled.img`
  margin-top: 28px;
`;

export const H3 = styled.h3`
  margin: 30px 0 20px;
  padding: 0;
  color: ${(props) => props.theme.terracottaColor};
  font-size: 16px;
  font-weight: 600;
`;

export const FormGroup = styled.div`
  margin-bottom: 16px;
  width: 100%;
  max-width: 340px;
  min-width: 200px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 35px;
  margin-bottom: 10px;
`;

export const A = styled(Link)`
  margin-top: 10px;
  color: ${(props) => props.theme.terracottaColor};
`;
