import React, {FC} from 'react';
import Modal from 'react-modal';
import crossIcon from '../../../assets/images/icons/cross_black.png';
import {CloseBtn, CloseIcon, Content, Header} from "./styled";

interface Prop extends Modal.Props{
    onClose?: any;
    title?: string;
    children?: any;
    maxWidth?: number;
}

const BaseModal: FC<Prop> = ({
        isOpen = false,
        onClose,
        title,
        children,
        maxWidth = 560,
        ...props
    }) => {

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            maxWidth: maxWidth,
            borderRadius: 4,
            border: 'none',
            padding: 0,
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 9999,
        },
    };

    return (
        <Modal
            isOpen={isOpen}
            appElement={document.getElementById('root') || undefined}
            style={customStyles}
            {...props}
        >
            <Header>
                <h2>{title}</h2>
                <CloseBtn onClick={onClose}>
                    <CloseIcon src={crossIcon} alt={'X'}/>
                </CloseBtn>
            </Header>
            <Content>
                {children}
            </Content>
        </Modal>
    );
};

export default BaseModal;
