import styled from "styled-components";
import PhoneInput from 'react-phone-input-2';

export const Wrapper = styled.div`
    display: block;
`;

export const StyledLabel = styled.label`
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: ${(props) => props.theme.greyColor};
`;

export const StyledPhoneInput = styled(PhoneInput)`
  .form-control {
    border-color: #ced4da;
    border-radius: 0;
    font-weight: 400;
    font-size: 16px;
    color: #000;
    height: 44px;
    width: 100%;
    
    &::placeholder {
      color: #a4b1b4;
    }

    & :focus {
      outline: none;
    }
  }
`;

export const Error = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: ${(props) => props.theme.errorColor};
`;