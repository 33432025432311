import React, {FC} from 'react';
import {Button} from "../../../../controls";
import {ButtonsWrapper} from "./styled";

type Props = {
    isEditMode: boolean,
    isNewChemical: boolean,
    isActive: boolean,
    isViewOnly: boolean,
    handleCancel: () => void,
    handleSave: () => void,
    handleBack: () => void;
    handleSetEditMode: () => void,
    handleChemicalArchive: (active: boolean) => void,
}

const Buttons: FC<Props> = ({isEditMode,isNewChemical, isViewOnly,  isActive, handleSave, handleCancel, handleBack, handleSetEditMode, handleChemicalArchive}) => {
    return(
       <ButtonsWrapper>
           {isViewOnly ? (
               <Button title={'Back'} color={'blue-transparent'} size='SM' icon='back' onClick={handleBack}/>
           ) : (
               <>
                   {!isEditMode && isActive && <Button title={'Archive Chemical'} color={'terracotta-transparent'} size='MD2' icon='archive' onClick={() => handleChemicalArchive(false)}/>}
                   {!isEditMode && !isActive && <Button title={'Activate Chemical'} color={'terracotta'} size='MD2' icon='activate' onClick={() => handleChemicalArchive(true)}/>}
                   {isEditMode
                       ? <Button title={`${isNewChemical ? 'Create' : 'Save'}`} color={'red-transparent'} size='SM' icon='save' onClick={handleSave}/>
                       : <Button title={'Edit'} color={'red-transparent'} size='SM' icon='edit' onClick={handleSetEditMode}/>
                   }
                   {isEditMode
                       ? <Button title={'Cancel'} color={'blue-transparent'} size='SM' icon='cancel' onClick={handleCancel}/>
                       : <Button title={'Back'} color={'blue-transparent'} size='SM' icon='back' onClick={handleBack}/>
                   }
               </>
           )}
       </ButtonsWrapper>
   );
};

export default Buttons;
