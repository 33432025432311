import React, {FC} from 'react';
import {Wrapper} from "./styled";

const NotFound: FC = () => {
    return (
        <Wrapper>
            <h1>Page Not Found</h1>
            <a href={'/'}>Go to main page</a>
        </Wrapper>
    );
};

export default NotFound;
