import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 400px 400px;
  column-gap: 20px;
`;

export const CellWrapper = styled.div`
  display: grid;
  grid-template-columns: 190px 190px;
  column-gap: 20px;
`;
export const Value = styled.div<{isBold?: boolean}>`
  padding: 4px;
  font-weight: ${(props) => props.isBold ? '700' : '500'};
`;
