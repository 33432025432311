import styled from "styled-components";

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledInput = styled.input<{variant: 'default'|'dark'}>`
  height: 0;
  width: 0;
  visibility: hidden;
  
  &:checked {
    & + label {
        background: ${({theme, variant = 'default'}) => variant === 'dark' ? theme.terracottaColor : theme.blue2Color};
    }

    & + label:after {
      left: calc(100% - 3px);
      transform: translateX(-100%);
    }
  }
`;

export const StyledLabel = styled.label<{variant: 'default'|'dark'}>`
  cursor: pointer;
  text-indent: -9999px;
  width: 38px;
  height: 20px;
  background: ${({variant = 'default'}) => variant === 'dark' ? 'rgba(255, 255, 255, 0.10)' : '#E4E4E4'};
  display: block;
  border-radius: 20px;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 14px;
    height: 14px;
    background: #fff;
    border-radius: 15px;
    transition: 0.3s;
  }
  
  &:active:after {
    width: 20px;
  }
`;

export const StyledToggleLabel = styled.label<{variant: 'default'|'dark'}>`
  margin-left: 8px;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-transform: uppercase;
  color: ${({theme, variant = 'default'}) => variant === 'dark' ? '#FFF' : theme.blackTextColor};
`;
